import React, { useCallback } from "react";
import "react-toggle/style.css";
import { FormGroup, Label } from "reactstrap";

export const FirstVisitSelector = ({ isFisrtVisit, setIsFirstVisit }) => {
  const handlerToggle = useCallback(
    (e) => {
      e.preventDefault();
      setIsFirstVisit((isFisrtVisit) => !isFisrtVisit);
    },
    [setIsFirstVisit]
  );

  return (
    <FormGroup className="form-group col">
      <Label for="firstVisitSelector">1er séjour à l’hôtel</Label>
      <div
        id="firstVisitSelector"
        onClick={handlerToggle}
        className="form-control flex-toggle-selector"
      >
        <div
          className={`toggle-box ${
            isFisrtVisit && "toggle-box-background toggle-box-left"
          }`}
        >
          Oui
        </div>
        <div
          className={`toggle-box ${
            !isFisrtVisit && "toggle-box-background toggle-box-right"
          }`}
        >
          Non
        </div>
      </div>
    </FormGroup>
  );
};

export default FirstVisitSelector;
