import React, { useCallback, useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../inputErrorMessage";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import format from "date-fns/format";
import fr from "date-fns/locale/fr";
const DateSelector = ({
  send,
  arrivalDate,
  setArrivalDate,
  departureDate,
  setDepartureDate,
}) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [showCalendar, setShowCalendar] = useState(false);

  // set arrivalData and departure date
  const handleRangeDate = useCallback(
    (item) => {
      const currentDate = new Date();

      const arrivalDate = item.selection.startDate;
      arrivalDate.setHours(currentDate.getHours());
      arrivalDate.setMinutes(currentDate.getMinutes());
      arrivalDate.setSeconds(currentDate.getSeconds());

      const departureDate = item.selection.endDate;
      departureDate.setHours(currentDate.getHours());
      departureDate.setMinutes(currentDate.getMinutes());
      departureDate.setSeconds(currentDate.getSeconds());

      setRange([item.selection]);
      setArrivalDate(arrivalDate.toISOString());
      setDepartureDate(departureDate.toISOString());
    },
    [setRange, setArrivalDate, setDepartureDate]
  );

  const toggleCalendarOn = () => {
    setShowCalendar(true);
  };

  const toggleCalendarOff = () => {
    setShowCalendar(false);
  };
  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="stayDate">
          Date d’arrivée et Date de départ <span className="gwPink">*</span>
          {send && !(arrivalDate && departureDate) && <InputErrorMessage />}
        </Label>
        <Input
          value={`${format(range[0].startDate, "dd/MM/yyyy")} ~ ${format(
            range[0].endDate,
            "dd/MM/yyyy"
          )}`}
          readOnly
          onClick={toggleCalendarOn} // Show the DateRange component on input click
        />
        {showCalendar && (
          <div className="flex-date-range">
            <DateRange
              onChange={handleRangeDate}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="horizontal"
              minDate={new Date()}
              locale={fr}
            />
            <Button
              className="checkin-button--color"
              onClick={toggleCalendarOff}
            >
              Ok
            </Button>
          </div>
        )}
      </FormGroup>
    </Row>
  );
};

export default DateSelector;
