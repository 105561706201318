import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function WelcomeModal(props) {
  const visited = localStorage.getItem(`${props.slug}AlreadyVisited`) || false;
  const [modal, setModal] = React.useState(props.showOnFirstVisit && !visited);
  const toggle = () => {
    localStorage.setItem(`${props.slug}AlreadyVisited`, true);
    setModal(!modal);
  }

  return (
    <div className="welcomeModal d-lg-none">
      <Modal isOpen={modal} toggle={toggle} modalTransition={{ timeout: 500 }} className="welcomeModal">
      <ModalHeader toggle={toggle} 
                  ></ModalHeader>
        <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default WelcomeModal;
