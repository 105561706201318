import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row, Media } from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import { useEffect } from "react";
import Select from "react-select";

import { PriceContext } from "../../../App";

const ItemInput = (props) => {
  const [quantity, setQuantity] = useState(0);

  const [selectedPrecisions, setSelectedPrecisions] = useState([]);

  useEffect(() => {
    let selected_precisions = [];
    if (selectedPrecisions.length > 0) {
      selected_precisions = [...selectedPrecisions];
      if (quantity > selected_precisions.length) {
        const precisions = props.item.precisions.map((precision) => {
          return {
            name: precision.name,
            choice: precision.name,
            price: 0,
          };
        });
        selected_precisions.push({ precisions: precisions });
      } else {
        selected_precisions.pop();
      }
    } else {
      if (props.item.selectedPrecisions) {
        selected_precisions = props.item.selectedPrecisions;
      } else {
        if (
          props.item.precisions !== undefined &&
          props.item.precisions.length > 0
        ) {
          const precisions = props.item.precisions.map((precision) => {
            return {
              name: precision.name,
              choice: precision.name,
              price: 0,
            };
          });
          selected_precisions = Array(quantity).fill({
            precisions: precisions,
          });
        } else {
          selected_precisions = [];
        }
      }
    }
    setSelectedPrecisions(selected_precisions);
  }, [quantity]);

  useEffect(() => {
    const updateItems = props.comm[props.currentCommande].sections[
      props.indexSection
    ].items.map((obj, index) => {
      if (index === props.indexItem) {
        return { ...obj, selectedPrecisions: selectedPrecisions };
      }
      return obj;
    });
    const updateSection = {
      ...props.comm[props.currentCommande].sections[props.indexSection],
      items: updateItems,
    };

    const updateSections = props.comm[props.currentCommande].sections.map(
      (obj, index) => {
        if (index === props.indexSection) {
          return updateSection;
        }
        return obj;
      }
    );

    const updateCommande = {
      ...props.comm[props.currentCommande],
      sections: updateSections,
    };

    const updateCommandes = props.comm.map((obj, index) => {
      if (index === props.currentCommande) {
        return updateCommande;
      }
      return obj;
    });
    props.setCommand(updateCommandes);
  }, [selectedPrecisions]);

  const updateSelection = async (_selected) => {
    const updateRadioItems = props.comm[props.currentCommande].sections[
      props.indexSection
    ].items.map((obj, index) => {
      if (index === props.indexItem) {
        return { ...obj, selected: _selected, quantity: _selected ? 1 : 0 };
      }
      return { ...obj, selected: false, quantity: 0 };
    });

    const updateItems = props.comm[props.currentCommande].sections[
      props.indexSection
    ].items.map((obj, index) => {
      if (index === props.indexItem) {
        return { ...obj, selected: _selected, quantity: _selected ? 1 : 0 };
      }
      return obj;
    });

    const updateSection = {
      ...props.comm[props.currentCommande].sections[props.indexSection],
      items: !props.multiple ? updateRadioItems : updateItems,
    };

    const updateSections = props.comm[props.currentCommande].sections.map(
      (obj, index) => {
        if (index === props.indexSection) {
          return updateSection;
        }
        return obj;
      }
    );

    const updateCommande = {
      ...props.comm[props.currentCommande],
      sections: updateSections,
    };

    const updateCommandes = props.comm.map((obj, index) => {
      if (index === props.currentCommande) {
        return updateCommande;
      }
      return obj;
    });

    props.setCommand(updateCommandes);
  };

  const updateQuantity = async (_quantity) => {
    const updateItems = props.comm[props.currentCommande].sections[
      props.indexSection
    ].items.map((obj, index) => {
      if (index === props.indexItem) {
        return { ...obj, quantity: _quantity };
      }
      return obj;
    });
    const updateSection = {
      ...props.comm[props.currentCommande].sections[props.indexSection],
      items: updateItems,
    };

    const updateSections = props.comm[props.currentCommande].sections.map(
      (obj, index) => {
        if (index === props.indexSection) {
          return updateSection;
        }
        return obj;
      }
    );

    const updateCommande = {
      ...props.comm[props.currentCommande],
      sections: updateSections,
    };

    const updateCommandes = props.comm.map((obj, index) => {
      if (index === props.currentCommande) {
        return updateCommande;
      }
      return obj;
    });
    props.setCommand(updateCommandes);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event);
    updateQuantity(event);
  };

  const handleChecked = (event) => {
    props.isClicked(event, props.indexItem);
    handleQuantityChange(event.target.checked ? 1 : 0);
    setQuantity(event.target.checked ? 1 : 0);
    updateSelection(event.target.checked);
  };

  const displayQuantity = (_quantity, quantityMax) => {
    return (
      <div className="quantity">
        <Col>
          {" "}
          <FormGroup className="form-group">
            <div className="quantitySpinner">
              <InputSpinner
                type={"int"}
                precision={1}
                name={"quantity"}
                id="quantity"
                max={
                  props.item.isStockManaged
                    ? Math.min(quantityMax, props.item.stockValue)
                    : quantityMax
                }
                min={1}
                step={1}
                value={_quantity}
                onChange={(event) => {
                  handleQuantityChange(event);
                }}
                variant={"light"}
                size="sm"
                editable={false}
              />
            </div>
          </FormGroup>
        </Col>
      </div>
    );
  };

  const getPrecisionChoicesNames = (_id) => {
    const choicesNames = props.item.precisions[_id].choices.map((item) => {
      return {
        value: { name: item.name, price: item.price },
        label: item.name + (item.price > 0 ? " (+" + item.price + "€)" : ""),
        color: "#00B8D9",
        isFixed: true,
      };
    });
    return choicesNames;
  };

  const setSelectedPrecisionChoice = (e, id, precisionId) => {
    let newPrecisions = [...selectedPrecisions];
    newPrecisions[id].precisions[precisionId].choice = e.value.name;
    newPrecisions[id].precisions[precisionId].price = e.value.price;
    setSelectedPrecisions(newPrecisions);
  };

  const DisplayPrecisionsSelects = () => {
    return (
      <>
        {props.selected &&
        props.item?.precisions?.length > 0 &&
        selectedPrecisions
          ? selectedPrecisions.map((person, id) => {
              return (
                <Row className="mb-3">
                  <div className="w-25 mx-auto">
                    <div className="w-25 mx-auto">
                      <div className="w-25 mx-auto">
                        <p>#{id + 1}</p>
                      </div>
                    </div>
                  </div>
                  <div className="ml-n5 w-75">
                    <Row>
                      {person.precisions.map((precision, precisionId) => {
                        return (
                          <Select
                            className="mb-2"
                            classNamePrefix="select"
                            defaultValue={{
                              value: precision.name,
                              label:
                                precision.choice +
                                (precision.price > 0
                                  ? " (+" + precision.price + "€)"
                                  : ""),
                              color: "#00B8D9",
                              isFixed: true,
                            }}
                            isSearchable={false}
                            name="precision-choices"
                            quantity
                            options={getPrecisionChoicesNames(precisionId)}
                            onChange={(e) =>
                              setSelectedPrecisionChoice(e, id, precisionId)
                            }
                          />
                        );
                      })}
                    </Row>
                  </div>
                </Row>
              );
            })
          : null}
      </>
    );
  };

  return (
    <>
      {props.multiple === true ? (
        <div>
          {props.item.image ? (
            <>
              <Input
                className={`hidden ${
                  props.required ? "MultipleCheckboxRequired" : ""
                }`}
                type="checkbox"
                name={`${props.item.label}-${props.step}${props.indexSection}${props.indexItem}`}
                id={`${props.item._id}-${props.step}${props.indexSection}${props.indexItem}`}
                value={props.item.label}
                multiple={true}
                max={props.limit}
                onChange={handleChecked}
                checked={props.selected}
                disabled={props.item.available === false}
              />
              <Label
                className={`typeCheckboxImg ${
                  props.item.available === false ? "unavailable" : ""
                }`}
                for={`${props.item._id}-${props.step}${props.indexSection}${props.indexItem}`}
                disabled={props.item.available === false}
              >
                <div className="col-12 d-flex">
                  <Col xs={8} className="item-infos">
                    <span className="item-title">
                      {props.item.label}{" "}
                      {props.item.available === false ? (
                        <span className="text-danger">
                          <i> - indisponible</i>
                        </span>
                      ) : null}
                    </span>
                    {props.item.description ? (
                      <i>
                        <br />
                        {props.item.description}
                      </i>
                    ) : null}
                  </Col>
                  <Col xs={4}>
                    {props.item.image ? (
                      <figure
                        style={{
                          backgroundImage: `url(${props.item.image})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></figure>
                    ) : null}
                    {props.item.price ? (
                      <div className="price_container typeImg">
                        <PriceContext.Consumer>
                          {(value) => <>{value({ value: props.item.price })}</>}
                        </PriceContext.Consumer>
                      </div>
                    ) : null}
                  </Col>
                  {/* </Row> */}
                </div>
              </Label>

              {props.selected &&
              props.item.quantityMax &&
              props.item.quantityMax > 1
                ? displayQuantity(
                    props.item.quantity ? props.item.quantity : quantity,
                    props.item.quantityMax
                  )
                : null}
              <DisplayPrecisionsSelects />
            </>
          ) : (
            <>
              <Label
                className={`typeCheckbox ${
                  props.item.available === false ? "unavailable" : ""
                }`}
              >
                {/* <Row> */}
                <Col xs={10} className="d-flex">
                  <Col xs={1} className="inputCol">
                    <Input
                      className={
                        props.required ? "MultipleCheckboxRequired" : ""
                      }
                      type="checkbox"
                      name={`${props.sectionLabel}-${props.step}${props.indexSection}${props.indexItem}`}
                      value={props.item.label}
                      onChange={(e) => handleChecked(e)}
                      max={props.limit}
                      checked={props.selected}
                      disabled={props.item.available === false}
                    />
                  </Col>
                  {/* </Col> */}
                  <Col xs={11} className="item-infos">
                    <span className="item-title">
                      {props.item.label}{" "}
                      {props.item.available === false ? (
                        <span className="text-danger">
                          <i> - indisponible</i>
                        </span>
                      ) : null}
                    </span>
                    {props.item.description ? (
                      <i>
                        <br />
                        {props.item.description}
                      </i>
                    ) : null}
                  </Col>
                </Col>
                {props.item.price ? (
                  <Col xs={2} className="price_container">
                    +
                    <PriceContext.Consumer>
                      {(value) => (
                        <>
                          {value({ value: props.item.price, style: "inline" })}
                        </>
                      )}
                    </PriceContext.Consumer>
                  </Col>
                ) : null}
                {/* </Row> */}
              </Label>
              {props.selected &&
              props.item.quantityMax &&
              props.item.quantityMax > 1
                ? displayQuantity(
                    props.item.quantity ? props.item.quantity : quantity,
                    props.item.quantityMax
                  )
                : null}
              <DisplayPrecisionsSelects />
            </>
          )}
        </div>
      ) : props.item.image ? (
        <>
          <Input
            className={props.required ? "typeRadioRequired" : ""}
            type="radio"
            id={props.item._id}
            name={`${props.sectionLabel}-${props.step}${props.indexSection}${props.indexItem}`}
            value={props.item.label}
            onChange={(e) => handleChecked(e)}
            checked={props.selected}
            max={props.limit}
            style={{ visibility: "hidden" }}
            disabled={props.item.available === false}
          />
          <Label
            className={`typeRadioImg ${
              props.item.available === false ? "unavailable" : ""
            }`}
            for={`${props.item._id}`}
          >
            <Col md={7} lg={9} className="item-infos">
              <span className="item-title">
                {props.item.label}{" "}
                {props.item.available === false ? (
                  <span className="text-danger">
                    <i> - indisponible</i>
                  </span>
                ) : null}
              </span>
              {props.item.description ? (
                <i>
                  <br />
                  {props.item.description}
                </i>
              ) : null}
            </Col>
            <Col md={5} lg={3} className="item-attr">
              {props.item.image ? (
                <figure
                  style={{
                    backgroundImage: `url(${props.item.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></figure>
              ) : null}
              {props.item.price ? (
                <Col className="price_container typeImg">
                  +{" "}
                  <PriceContext.Consumer>
                    {(value) => (
                      <>{value({ value: props.item.price, style: "inline" })}</>
                    )}
                  </PriceContext.Consumer>
                </Col>
              ) : null}
            </Col>
            {/* </Row> */}
          </Label>
          {props.selected &&
          props.item.quantityMax &&
          props.item.quantityMax > 1
            ? displayQuantity(
                props.item.quantity ? props.item.quantity : quantity,
                props.item.quantityMax
              )
            : null}
          <DisplayPrecisionsSelects />
        </>
      ) : (
        <div>
          <Label
            className={`typeRadio ${
              props.item.available === false ? "unavailable" : ""
            }`}
            for={`${props.item._id}`}
          >
            <Col className="item-infos" xs={12}>
              <Input
                id={props.item._id}
                className={props.required ? "typeRadioRequired" : ""}
                type="radio"
                name={`${props.sectionLabel}-${props.step}${props.indexSection}${props.indexItem}`}
                value={props.item.label}
                onChange={(e) => handleChecked(e)}
                checked={props.selected}
                disabled={props.item.available === false}
              />
              <Col>
                <span>
                  {props.item.label}{" "}
                  {props.item.available === false ? (
                    <span className="text-danger">
                      <i> - indisponible</i>
                    </span>
                  ) : null}
                </span>
                <br />
                {props.item.description ? (
                  <i>{props.item.description}</i>
                ) : null}
              </Col>
              {props.item.price ? (
                <Col xs={2} className="price_container">
                  +{" "}
                  <PriceContext.Consumer>
                    {(value) => (
                      <>{value({ value: props.item.price, style: "inline" })}</>
                    )}
                  </PriceContext.Consumer>
                </Col>
              ) : null}
            </Col>
            {props.selected &&
            props.item.quantityMax &&
            props.item.quantityMax > 1 ? (
              <Col xs={12}>
                {displayQuantity(
                  props.item.quantity ? props.item.quantity : quantity,
                  props.item.quantityMax
                )}
              </Col>
            ) : null}
            <DisplayPrecisionsSelects />
          </Label>
        </div>
      )}
    </>
  );
};

export default ItemInput;
