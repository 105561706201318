import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { withRouter } from "../../../router/withRouter";

////**ICONS
import { ReactComponent as IconPressing } from "../../../assets/images/pages/roomdirectory/category/icon-pressing.svg";
import { ReactComponent as IconCoffee } from "../../../assets/images/pages/roomdirectory/category/icon-coffee.svg";
import { ReactComponent as IconBalloon } from "../../../assets/images/pages/roomdirectory/category/icon-balloon.svg";
import { ReactComponent as IconBed } from "../../../assets/images/pages/roomdirectory/category/icon-bed.svg";
import { ReactComponent as IconBrush } from "../../../assets/images/pages/roomdirectory/category/icon-brush.svg";
import { ReactComponent as IconCheck } from "../../../assets/images/pages/roomdirectory/category/icon-check.svg";
import { ReactComponent as IconDoorRestaurant } from "../../../assets/images/pages/roomdirectory/category/icon-restaurant.svg";
import { ReactComponent as IconEnvelope } from "../../../assets/images/pages/roomdirectory/category/icon-envelope.svg";
import { ReactComponent as IconHome } from "../../../assets/images/pages/roomdirectory/category/icon-home.svg";
import { ReactComponent as IconHumans } from "../../../assets/images/pages/roomdirectory/category/icon-humans.svg";
import { ReactComponent as IconInfos } from "../../../assets/images/pages/roomdirectory/category/icon-infos.svg";
import { ReactComponent as IconKey } from "../../../assets/images/pages/roomdirectory/category/icon-key.svg";
import { ReactComponent as IconList } from "../../../assets/images/pages/roomdirectory/category/icon-list.svg";
import { ReactComponent as IconLoop } from "../../../assets/images/pages/roomdirectory/category/icon-loop.svg";
import { ReactComponent as IconMap } from "../../../assets/images/pages/roomdirectory/category/icon-map.svg";
import { ReactComponent as IconMusic } from "../../../assets/images/pages/roomdirectory/category/icon-music.svg";
import { ReactComponent as IconPhone } from "../../../assets/images/pages/roomdirectory/category/icon-phone.svg";
import { ReactComponent as IconPresent } from "../../../assets/images/pages/roomdirectory/category/icon-present.svg";
import { ReactComponent as IconSchedule } from "../../../assets/images/pages/roomdirectory/category/icon-schedule.svg";
import { ReactComponent as IconShopping } from "../../../assets/images/pages/roomdirectory/category/icon-shopping.svg";
import { ReactComponent as IconSingleHuman } from "../../../assets/images/pages/roomdirectory/category/icon-singlehuman.svg";
import { ReactComponent as IconSpot } from "../../../assets/images/pages/roomdirectory/category/icon-spot.svg";
import { ReactComponent as IconWarning } from "../../../assets/images/pages/roomdirectory/category/icon-warning.svg";
import { ReactComponent as IconWifi } from "../../../assets/images/pages/roomdirectory/category/icon-wifi.svg";
import { ReactComponent as IconArrow } from "../../../assets/images/icon-previous.svg";

import Module from "./Modules";
// import axios from "axios";

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host: props.host,
      roomdirectory: props.roomdirectory,
      content: [],
      toggle: null,
      slug: props.slug, 
    };
    // this.toggle = this.toggle.bind(this);
  }

  handleToggle = async (id) => {
    if (this.state.toggle === id) {
      this.setState({ toggle: null });
      return false;
    }
    this.setState({ toggle: id });
  };

  scrollToTopCategory = async (id) => {
    const target = id + "_tagcat"
    document.getElementById(target).scrollIntoView()
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.host !== this.state.host) {
      this.setState({ host: nextProps.host });
    }
    if (nextProps.roomdirectory !== this.state.roomdirectory) {
      this.setState({ roomdirectory: nextProps.roomdirectory });
    }
    if (nextProps.slug !== this.state.slug) {
        this.setState({ slug: nextProps.slug });
      }
  }

  generateIcon = (_icon) => {
    switch (_icon) {
      case "balloon":
        return <IconBalloon />;
      case "bed":
        return <IconBed />;
      case "brush":
        return <IconBrush />;
      case "check":
        return <IconCheck />;
      case "coffee":
        return <IconCoffee />;
      case "restaurant":
        return <IconDoorRestaurant />;
      case "envelope":
        return <IconEnvelope />;
      case "home":
        return <IconHome />;
      case "humans":
        return <IconHumans />;
      case "infos":
        return <IconInfos />;
      case "key":
        return <IconKey />;
      case "list":
        return <IconList />;
      case "loop":
        return <IconLoop />;
      case "map":
        return <IconMap />;
      case "music":
        return <IconMusic />;
      case "phone":
        return <IconPhone />;
      case "present":
        return <IconPresent />;
      case "pressing":
        return <IconPressing />;
      case "schedule":
        return <IconSchedule />;
      case "shopping":
        return <IconShopping />;
      case "singlehuman":
        return <IconSingleHuman />;
      case "spot":
        return <IconSpot />;
      case "warning":
        return <IconWarning />;
      case "wifi":
        return <IconWifi />;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        {this.state.roomdirectory
          ? this.state.roomdirectory.data[0].sections.map((cat) => {
              return (
                <>
                {cat.visible ? 
                <Card
                  className={`category ${
                    cat._id === this.state.toggle ? "opened" : ""
                  } ${cat.content.length === 0 /* || cat.visible === false */ ? "d-none" : ""}`}
                  key={cat._id}
                >
                  <CardHeader
                    onClick={() => {this.handleToggle(cat._id).then(() => this.scrollToTopCategory(cat._id))}}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="header_card">
                      <Row className="inlineIcon">
                        <Col md={1}>
                          {cat.icon ? this.generateIcon(cat.icon) : null}
                        </Col>
                        <Col className="category__infos">
                          <h4>{cat.title}</h4>
                          {cat.description ? <i>{cat.description}</i> : null}
                        </Col>
                      </Row>
                      <IconArrow
                        style={{
                          transform:
                            cat._id === this.state.toggle
                              ? "rotate(90deg)"
                              : "rotate(-90deg)",
                        }}
                      />
                    </div>
                  </CardHeader>
                  <div className="target_marker" id={`${cat._id}_tagcat`}></div>

                  {cat._id === this.state.toggle ? (
                    <CardBody>
                      {cat.content.length > 0
                        ? cat.content.map((module) => {
                            return (
                              <Module
                                type={module.type}
                                options={module.options}
                                slug={this.state.slug}
                              />
                            );
                          })
                        : null }
                    </CardBody>
                  ) : (
                    ""
                  )}
                </Card> : null} </>
              );
            })
          : null}
      </>
    );
  }
}

export default withRouter(Categories);
