import React from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { SatisfactionEnum } from "../../../../constants/feedbackType";

export const FeedbackRequestModal = ({
  message,
  setMessage,
  satisfactionLevel,
}) => {
  return (
    <>
      {/* Label for message input section */}
      <Label for="clientInfos" className="feedback-label-margin">
        <span className="feedback-label">{`Expliquez nous pourquoi ? ${
          satisfactionLevel !== SatisfactionEnum.SATISFIED ? " *" : ""
        }`}</span>
      </Label>

      {/* Message input box */}
      <Row>
        <FormGroup className="form-group col">
          <Input
            rows={5}
            required
            id="message"
            name="message"
            placeholder={
              satisfactionLevel === SatisfactionEnum.SATISFIED
                ? "Un message pour notre équipe ?"
                : "Que se passe t'il ? Comment pouvons nous vous aider ? "
            }
            type="textarea"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </FormGroup>
      </Row>
    </>
  );
};
