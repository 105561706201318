import React, { Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardColumns,
  CardText,
  CardTitle,
  CardFooter,
  Button,
  CardHeader,
} from "reactstrap";

import { Link } from "react-router-dom";
import DefaultImgActivities from "../../../assets/images/default-activity.jpg";

import Leisure from "../../../assets/images/pages/partners/leisure.jpg";
import * as Sport from "../../../assets/images/pages/partners/sport.jpg";
import * as Gastronomy from "../../../assets/images/pages/partners/gastronomy.jpg";
import * as Escape from "../../../assets/images/pages/partners/escape.jpg";
import * as Shop from "../../../assets/images/pages/partners/shop.jpg";
import * as Experience from "../../../assets/images/pages/partners/experience.jpg";
import * as Discovery from "../../../assets/images/pages/partners/discovery.jpg";

import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
class Offers extends React.Component {
  constructor(props) {
    super();
    this.state = {
      offers: props.offers.sort((a, b) => {
        return a.indexPosition - b.indexPosition;
      }),
      hosterType: props.hosterType,
      city: props.hosterCity,
    };
  }

  getType = (type) => {
    switch (type) {
      case "gastronomy":
        return "Gastronomie";
      case "leisure":
        return "Loisir";
      case "escape":
        return "Evasion";
      case "discovery":
        return "Découverte";
      case "experience":
        return "Expérience";
      case "shop":
        return "Commerce";
      case "sport":
        return "Sport";
      case "eat":
        return "Manger";
      case "haveadrink":
        return "Boire un verre";
      case "nightlife":
        return "Vie nocturne";
      case "child":
        return "Enfant";
      case "activities":
        return "Activités";
      default:
        return "";
    }
  };

  getDefaultImage = (type) => {
    switch (type) {
      case "gastronomy":
        return Gastronomy.default;
      case "sport":
        return Sport.default;
      case "escape":
        return Escape.default;
      case "shop":
        return Shop.default;
      case "leisure":
        return Leisure.default;
      case "discovery":
        return Discovery.default;
      case "experience":
        return Experience.default;
      case "eat":                     //temporary
        return Experience.default;
      case "haveadrink":
        return Experience.default;
      case "nightlife":
        return Experience.default;
      case "child":
        return Experience.default;
      case "activities":
        return Experience.default;
      case "":
        return Leisure.default;
      default:
        return Leisure.default;
    }
  };

  render() {
    return (
      <Container className="mainOffers">
        <p className="headerDescription">
          Découvrez les activités et expériences{" "}
          {this.state.hosterType
            ? "en partenariat avec votre " + this.state.hosterType
            : null}
          .
        </p>
        <Container className="offersGroup mt-4 mb-4 justify-content-center align-items-center">
          {this.state.offers.map((offer) => {
            return (
              <Fragment key={offer._id}>
                <div id={offer._id} className="ancor"></div>
                <CardColumns className="row row-cols-12 row-cols-lg-12">
                  <Card
                    className=" col-12 offer bg-light"
                    key={offer._id}
                    //   onClick={(e) => this.showModal(service)}
                  >
                    <Link to={`${offer._id}/`}>
                      <Row>
                        <div className="col-5 col-lg-4 img">
                          <div className="pills">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9.501"
                              height="5"
                              viewBox="0 0 9.501 5"
                            >
                              <path
                                id="Tracé_40"
                                data-name="Tracé 40"
                                d="M3.321,1.566,9.5,0V5H0C0,2.239,2.216,2.051,3.321,1.566Z"
                              />
                            </svg>{" "}
                            <span>{this.getType(offer.type)}</span>
                          </div>
                          {offer.images.length > 0 ? (
                            <figure
                              className="cardImg-top"
                              style={{
                                backgroundImage: `url(${offer.images[0]})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            ></figure>
                          ) : (
                            <figure
                              className="cardImg-top"
                              style={{
                                backgroundImage: `url(${this.getDefaultImage(
                                  offer.type
                                )})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            ></figure>
                          )}
                        </div>
                        {/* Add a marketing pills : <span className="bonplan"> Bon plan</span> */}
                        <div className="col-7 col-lg-8 description">
                          <CardHeader>
                            <span className="CardCategory">
                              {offer.subType}
                            </span>
                            <CardTitle tag="h5">{offer.label}</CardTitle>
                          </CardHeader>
                          <CardBody className="offerBody">
                            <CardText className="mt-2 px-2 description">
                              <IconSpot />
                              <div>
                                <span>
                                  {offer.address.line1},{" "}
                                  {offer.address.postalCode}{" "}
                                  {offer.address.city}
                                </span>
                                <br />
                                {/* <i className="distance">500m</i> */}
                              </div>
                            </CardText>
                            <CardText className="mt-2 px-2 description_mobile d-sm-block d-md-none">
                              <IconSpot />
                              <span>{offer.address.line1}</span>
                              <br />
                              <span>
                                {offer.address.postalCode} {offer.address.city}
                              </span>
                              <br />
                              {/* <i className="distance">500m</i> */}
                            </CardText>
                          </CardBody>
                          <CardFooter>
                            <Row className="AddBtn-container">
                              <Col>
                                <Button
                                  className="AddBtn"
                                  // onClick={(e) => this.showModal(service)}
                                >
                                  En savoir +
                                </Button>
                              </Col>
                            </Row>
                          </CardFooter>
                        </div>
                      </Row>
                      <Row>
                        <div className="Offer">
                          <span>{offer.offerTitle}</span>
                        </div>
                      </Row>
                    </Link>
                  </Card>
                </CardColumns>
              </Fragment>
            );
          })}
        </Container>
      </Container>
    );
  }
}

export default Offers;
