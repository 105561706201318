import React from "react";
import {
  Col,
  Row,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import axios from "axios";
import { withRouter } from "../../../router/withRouter";
import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
import { ReactComponent as IconTel } from "../../../assets/images/icon-telephone.svg";
import { ReactComponent as IconEmail } from "../../../assets/images/icon-email.svg";
import { ReactComponent as IconPrev } from "../../../assets/images/icon-previous.svg";
import { ReactComponent as IconOpen } from "../../../assets/images/icon-open.svg";
import { ReactComponent as IconDuration } from "../../../assets/images/icon-duration.svg";

import defaultImage from "../../../assets/images/default-activity.jpg";
import Map from "../../components/googlemap/googlemap";
import {PriceContext} from "../../../App"
class ActivityDetails extends React.Component {
  constructor(props) {
    super(props);

    this.getHostActivities = this.getHostActivities.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.state = {
      isConfirm: false,
      offer_id: this.props.router.params.id,
      activeIndex: 0,
      offers: {},
      items: [],
      hoster: {
        hosterName: props.hosterName,
        hosterType: props.hosterType,
        hosterLat: props.hosterLat,
        hosterLong: props.hosterLong,
      },
      activity: {
        activityName: null,
        activityLat: null,
        activityLong: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.hosterType !== this.state.hoster.hosterType) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterType: nextProps.hosterType,
        },
      }));
    }
    if (nextProps.hosterLat !== this.state.hoster.hosterLat) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterLat: nextProps.hosterLat,
        },
      }));
    }
    if (nextProps.hosterLong !== this.state.hoster.hosterLong) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterLong: nextProps.hosterLong,
        },
      }));
    }
    if (nextProps.hosterName !== this.state.hoster.hosterName) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterName: nextProps.hosterName,
        },
      }));
    }
  }

  getHostActivities = async () => {
    return axios
      .get(
        process.env.REACT_APP_API_STRAPI + "/activities/" + this.state.offer_id
      )
      .then((response) => {
        this.setState((prevState) => ({
          offers: response.data,
          items: response.data.photos,
          activity: {
            ...prevState.activity,
            activityName: response.data.place_name,
            activityLat: response.data.latitude ? response.data.latitude : null,
            activityLong: response.data.longitude
              ? response.data.longitude
              : null,
          },
        }));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  async componentDidMount() {
    await this.getHostActivities();
  }

  product_type(product_type) {
    switch (product_type) {
      case "CityPass":
        return "City Pass";
      case "BilletEntree":
        return "Billet d'entrée";
      case "VisiteGuidee":
        return "Visite guidée";
      case "ExcursionJournee":
        return "Excursion à la journée";
      case "VisitePrivee":
        return "Visite privée";
      case "CircuitArretsMultiples":
        return "Circuit à arrêts multiples";
      case "ActiviteAquatique":
        return "Activité aquatique";
      case "AtelierCours":
        return "Atelier ou Cours";
      case "BilletAcompagnateur":
        return "Billet avec accompagnateur";
      case "OffreCombinee":
        return "offre combinée";

      default:
        return product_type;
    }
  }

  getAddress = (address, hosterType) => {
    if (address === "Hébergement" && hosterType !== null) {
      return "à votre " + hosterType;
    } else {
      return address;
    }
  };

  goBack() {
    window.history.back();
  }

  render() {
    const { activeIndex } = this.state;
    const slides = this.state.items ? (
      this.state.items.map((item) => {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={item.url}
          >
            <figure
              className="cardImg-top"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_API_STRAPI + item.url
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></figure>
          </CarouselItem>
        );
      })
    ) : (
      <CarouselItem>
        <figure
          style={{
            backgroundImage: `url(${defaultImage})`,
            backgroundSize: "cover",
            // backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></figure>
      </CarouselItem>
    );
    return (
      <div>
        {this.state.isConfirm ? (
          <div className="success-box">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <p>Merci pour votre réservation !</p>
            <p>
              Nous vous contacterons au plus vite pour personnaliser votre
              demande.
            </p>
            <Button className="customBtn btn-blue" onClick={this.onClose}>
              Ok
            </Button>
          </div>
        ) : (
          <div className="ActivitySection">
            <button className="back" onClick={this.goBack}>
              <IconPrev />
              Nos activités
            </button>
            <div className="bbody container">
              <Row className="m-0">
                {/* <div className="box-shadow"> */}
                <Carousel
                  dark
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                >
                  <CarouselIndicators
                    items={this.state.items}
                    activeIndex={activeIndex}
                    onClickHandler={this.goToIndex}
                  />
                  {slides}
                  {this.state.items.length > 0 ? (
                    <>
                      {" "}
                      <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                      />
                    </>
                  ) : null}
                </Carousel>
                {this.state.offers.product_type ? (
                  <div className="pills">
                    <span>
                      {this.product_type(this.state.offers.product_type)}
                    </span>
                  </div>
                ) : null}
                <Row className="ActivityBody">
                  <Col className="sectionActivity col-12 col-lg-8">
                    <div className="infoActivity">
                      <Row>
                        <div className="title">{this.state.offers.title_long}</div>
                      </Row>
                      {/* <Row> */}
                      <div className="itemDetail">
                        <h4>Détails de l'offre</h4>
                        <span>{this.state.offers.activity_description}</span>
                        <br />
                        <br />
                        <h4>Comment en bénéficier</h4>
                        <span>{this.state.offers.activity_condition}</span>
                        <br />
                        <br />
                        {this.state.offers.address &&
                        this.state.hoster.hosterLat !== null &&
                        this.state.hoster.hosterLong !== null &&
                        this.state.activity.activityLat !== null &&
                        this.state.activity.activityLong !== null ? (
                          <>
                            <h4>Point de rencontre</h4>
                            <div className="localisation">
                              <a
                                href={`https://www.google.fr/maps/dir/'${this.state.hoster.hosterLat},${this.state.hoster.hosterLong}'/'${this.state.activity.activityLat},${this.state.activity.activityLong}'/`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <IconSpot />

                                <span>
                                  {this.getAddress(
                                    this.state.offers.address,
                                    this.state.hoster.hosterType
                                  )}
                                  ,{' '}
                                </span>
                                <span>
                                  {this.state.offers.postalcode}{' '}
                                  {this.state.offers.city}.
                                </span>
                              </a>
                              <Map
                                activity={this.state.activity}
                                hoster={this.state.hoster}
                              />
                            </div>
                          </>
                        ) : null}
                        <br />
                        <br />
                        {this.state.offers.duration !== null ? (
                          <>
                            <h4>Durée de l'expérience</h4>
                            <div className="duration">
                              <IconDuration />
                              <span>{this.state.offers.duration}</span>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <a
                    href={this.state.offers.activity_link}
                    className="col Infos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <Col className="Infos"> */}
                    <Row className="price">
                      <span>{this.state.offers.activity_price_mention}</span>

                      <PriceContext.Consumer>
                              {value => (
                                <>{value({value: this.state.offers.activity_price})}</>
                              )}
                            </PriceContext.Consumer>
                      <span>{this.state.offers.activity_price_modality}</span>
                    </Row>
                    <Row>
                      <Button
                        className="customBtn btn-blue smooth-scroll btn-book"
                        href={this.state.offers.activity_link}
                        target="_blank"
                      >
                        Réserver en ligne
                      </Button>
                    </Row>
                    {/* </Col> */}
                  </a>
                </Row>
              </Row>
            </div>
            <div className="custom_footer_details ">
              <Col className="price">
                <span>{this.state.offers.activity_price_mention}</span>
                <h3>
                <PriceContext.Consumer>
                              {value => (
                                <>{value({value: this.state.offers.activity_price})}</>
                              )}
                            </PriceContext.Consumer></h3>
                <span>{this.state.offers.activity_price_modality}</span>
              </Col>
              <Col>
                <Button
                  className="customBtn btn-blue smooth-scroll btn-book"
                  href={this.state.offers.activity_link}
                  target="_blank"
                >
                  Réserver en ligne
                </Button>
              </Col>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ActivityDetails);
