import React, { useCallback } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { InputErrorMessage } from "./inputErrorMessage";

const Signature = ({ send, signature, setSignature, signatureRef }) => {
  const uploadSignature = useCallback(
    async (e) => {
      e.preventDefault();
      const canvas = signatureRef.current;
      const dataURL = canvas.toDataURL();
      setSignature(dataURL.replace(/^data:image\/\w+;base64,/, ""));
    },
    [setSignature, signatureRef?.current]
  );

  const handleClearSignature = useCallback(
    (e) => {
      e.preventDefault();
      signatureRef.current.clear();
      setSignature("");
    },
    [signatureRef?.current, setSignature]
  );
  return (
    <Row>
      <FormGroup className="form-group col">
        <div className="signature-flex">
          <Label for="signature">
            Signature<span className="gwPink">*</span>
            {send && !signature && <InputErrorMessage />}
          </Label>
          <Button
            onClick={handleClearSignature}
            className="button-signature checkin-button--color"
          >
            <span>effacer</span>
          </Button>
        </div>
        <SignatureCanvas
          id="signature"
          ref={signatureRef}
          clearOnResize={false}
          canvasProps={{
            className: "form-control signature-height",
            required: true,
          }}
          onEnd={uploadSignature}
        />
      </FormGroup>
    </Row>
  );
};

export default Signature;
