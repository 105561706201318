import React, { useCallback, useRef, useState } from "react";
import Header from "../../components/checkin/header";
import { useInput } from "../../../lib/hooks";
import axios from "axios";
import NotFound from "../NotFound";
import { Button, Col, Form, Label, Spinner } from "reactstrap";
import { ConfirmModal } from "../../components/checkin/confirmModal";
import { IdentityComponent } from "../../components/checkin/IdentityComponent";
import { StayInfoComponent } from "../../components/checkin/StayInfoComponent";
import Signature from "../../components/checkin/signature";
import { VerifyList } from "../../components/checkin/verifyList";
import moment from "moment";
import {
  BucketFolder,
  convertBase64ToFormData,
} from "../../../lib/convertBase64ToFormData";

const Checkin = ({ slug, hosterId, name }) => {
  // ref for signature
  const signatureRef = useRef();
  // A trigger that checks if the submit button was pressed
  const [send, setSend] = useState(false);
  // checker for request api
  const [isConfirm, setIsConfirm] = useState(false);
  // IdentityComponent
  const [firstName, , handleFirstNameChange] = useInput("");
  const [lastName, , handleLastNameChange] = useInput("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [nation, setNation] = useState({ value: "FR", label: "France" });
  const [birthday, setBirthday] = useState();
  const [placeOfBirth, , handlePlaceOfBirthChange] = useInput("");
  const [passport, setPassport] = useState("");
  // StayInfoComponent
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });
  const [personCount, setPersonCount] = useState(1);
  const [isValidPersonCountFormat, setIsValidPersonCountFormat] =
    useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accommodationPurpose, setAccommodationPurpose] = useState("work");
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [estimatedArrivalTime, setEstimatedArrivalTime] = useState(() => {
    const currentTime = moment();
    const currentMinute = parseInt(currentTime.format("mm"));
    const currentHour = currentTime.format("HH");
    const hour =
      currentMinute > 45
        ? (parseInt(currentHour) + 1).toString().padStart(2, "0")
        : currentHour;
    const minute = (Math.ceil(currentTime.minute() / 15) * 15).toString();
    return {
      hour,
      minute,
    };
  });

  //VerifyList
  const [smokingInRoom, setSmokingInRoom] = useState(false);
  const [billPaymentResponsibility, setBillPaymentResponsibility] =
    useState(false);
  // Signature
  const [signature, setSignature] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // set send true
      setSend(true);

      // protection des infos
      if (
        !smokingInRoom ||
        !billPaymentResponsibility ||
        !personCount ||
        !departureDate ||
        !arrivalDate ||
        isEmailError ||
        !firstName ||
        !lastName ||
        !email ||
        !signature ||
        !nation ||
        !birthday ||
        !placeOfBirth ||
        !isValidPersonCountFormat ||
        !estimatedArrivalTime
      ) {
        return;
      }

      setButtonDisabled(true);

      const customerId = await axios
        .post(`${process.env.REACT_APP_API_URL}/customer`, {
          hoster: hosterId,
          firstname: firstName,
          lastname: lastName,
          phone: phoneNumber,
          email: email,
          birthDate: birthday,
          birthPlace: { city: placeOfBirth },
          nationalityCode: nation.value,
          overwriting: false,
        })
        .then((response) => response.data._id);

      const signatureKey = await convertBase64ToFormData(
        signature,
        customerId,
        BucketFolder.Signatures
      );

      const passportKey = await convertBase64ToFormData(
        signature,
        customerId,
        BucketFolder.Passports
      );
      // axios request with infos

      axios
        .post(`${process.env.REACT_APP_API_URL}/checkin`, {
          signature: signatureKey,
          passport: passportKey,
          personCount,
          arrivalDate,
          departureDate,
          isFirstVisit,
          accommodationPurpose,
          estimatedArrivalTime: estimatedArrivalTime,
          hosterId,
          customer: customerId,
        })
        .then((res) => {
          setIsConfirm(true);
        })
        .catch((error) => {
          setButtonDisabled(false);
          return;
        });
    },
    [
      send,
      arrivalDate,
      firstName,
      lastName,
      email,
      phoneNumber,
      signature,
      isConfirm,
      passport,
      nation,
      birthday,
      placeOfBirth,
      departureDate,
      personCount,
      accommodationPurpose,
      isFirstVisit,
      smokingInRoom,
      billPaymentResponsibility,
      isValidPersonCountFormat,
      estimatedArrivalTime,
    ]
  );

  const isShowCheckinModal =
    slug === "hotelsuede" || slug === "hwsg" || slug === "hotelparklane";

  return (
    <Col className="MessageFormMobile checkin">
      {isShowCheckinModal ? (
        <React.Fragment>
          {!isConfirm ? (
            <Form inline="true" className="infosForm">
              <Header name={name} slug={slug} />

              {/* identifié */}
              <Label for="clientInfos" className="checkin-label-margin">
                <span className="checkin-label">Identité</span>
              </Label>
              <IdentityComponent
                id="clientInfos"
                send={send}
                firstName={firstName}
                handleFirstNameChange={handleFirstNameChange}
                lastName={lastName}
                handleLastNameChange={handleLastNameChange}
                email={email}
                setEmail={setEmail}
                setIsEmailError={setIsEmailError}
                isEmailError={isEmailError}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                nation={nation}
                setNation={setNation}
                birthday={birthday}
                setBirthday={setBirthday}
                placeOfBirth={placeOfBirth}
                handlePlaceOfBirthChange={handlePlaceOfBirthChange}
                setPassport={setPassport}
              />

              {/* Votre séjour  */}
              <Label for="stayInfos" className="checkin-label-margin">
                <span className="checkin-label">Votre séjour</span>
              </Label>
              <StayInfoComponent
                id="stayInfos"
                send={send}
                arrivalDate={arrivalDate}
                setArrivalDate={setArrivalDate}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                personCount={personCount}
                setPersonCount={setPersonCount}
                isValidPersonCountFormat={isValidPersonCountFormat}
                setIsValidPersonCountFormat={setIsValidPersonCountFormat}
                setAccommodationPurpose={setAccommodationPurpose}
                isFirstVisit={isFirstVisit}
                setIsFirstVisit={setIsFirstVisit}
                estimatedArrivalTime={estimatedArrivalTime}
                setEstimatedArrivalTime={setEstimatedArrivalTime}
              />

              {/* engagement */}
              <Label for="verifyList" className="checkin-label-margin"></Label>
              <VerifyList
                id="verifyList"
                send={send}
                smokingInRoom={smokingInRoom}
                setSmokingInRoom={setSmokingInRoom}
                billPaymentResponsibility={billPaymentResponsibility}
                setBillPaymentResponsibility={setBillPaymentResponsibility}
              />

              {/* signature */}
              <Label for="signature" className="checkin-label-margin"></Label>
              <Signature
                id="signature"
                send={send}
                signature={signature}
                setSignature={setSignature}
                signatureRef={signatureRef}
              />

              {/* button */}
              <div className="BtnContainer">
                <Button
                  onClick={handleSubmit}
                  className="checkin-button--color"
                  disabled={isButtonDisabled}
                >
                  Envoyer
                  {isButtonDisabled ? <Spinner color="white" size="" /> : null}
                </Button>
              </div>
            </Form>
          ) : (
            <ConfirmModal slug={slug} feedbackType={`check-in`} />
          )}
        </React.Fragment>
      ) : (
        <NotFound />
      )}
    </Col>
  );
};

export default Checkin;
