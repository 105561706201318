import React from "react";

export const InputErrorMessage = ({ message }) => {
  return (
    <span
      style={{
        color: "red",
        marginLeft: "1rem",
      }}
    >
      {message ? message : "Ce champ est obligatoire"}
    </span>
  );
};
