import React, { useEffect } from "react";

export default function CallUsPhone({ slug }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.3cx.com/livechat/v1/callus.js";

    script.defer = true;

    script.id = "tcx-callus-js";

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, [slug]);

  const callUsConf = {
    hotelparklane: {
      partyId: "LiveChat962078",
      channelUrl: "https://voip-hotelparklane.my3cx.fr:5001",
    },
    hwsg: {
      partyId: "LiveChat194016",
      channelUrl: "https://moderne.my3cx.fr:5001",
    },
    demotelephonie: {
      partyId: "LiveChat191876",
      channelUrl: "https://voip.yelpp.fr:5001",
    },
    thfontaine: {
      partyId: "LiveChat203964",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
    thnation: {
      partyId: "LiveChat264236",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
    "th-gare-du-nord": {
      partyId: "LiveChat888990",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
    "th-tour-eiffel": {
      partyId: "LiveChat928823",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
    "th-montparnasse": {
      partyId: "LiveChat237828",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
    "th-paris-gare-est": {
      partyId: "LiveChat983432",
      channelUrl: "https://voip-getwelcom.on3cx.fr:5001",
    },
  };

  if (slug === undefined || slug === "" || !(slug in callUsConf)) {
    return null;
  }

  const createMyLabel = (myButton) => {
    const label = document.createElement("p");
    label.innerText = "Appeler la réception";
    myButton.appendChild(label);
  };

  const labelTranslate = (newLang) => {
    var textValue = "Appeler la réception";
    switch (newLang) {
      case "en":
        textValue = "Call reception";
        break;
      case "es":
        textValue = "Llamar a la recepción";
        break;
      case "fr":
        textValue = "Appeler la réception";
        break;
      case "it":
        textValue = "Ricezione delle chiamate";
        break;
      case "de":
        textValue = "Die rezeption anrufen";
        break;
      case "pt":
        textValue = "Receção de chamadas";
        break;
      default:
        textValue = "Call reception";
        break;
    }
    var callUsPhone = document.querySelector("call-us-phone");
    if (callUsPhone)
      callUsPhone.shadowRoot.querySelector(
        "#wplc-chat-button > p"
      ).textContent = textValue;
  };

  const styleButton = () => {
    const button = document
      .querySelector("call-us-phone")
      .shadowRoot.querySelector("button");

    if (!button.querySelector("p")) {
      createMyLabel(button);
    }

    const svg = document
      .querySelector("call-us-phone")
      .shadowRoot.querySelector("svg");

    const buttonContainer = document
      .querySelector("call-us-phone")
      .shadowRoot.querySelector(".root_3iK1E");
    const container = document
      .querySelector("call-us-phone")
      .shadowRoot.querySelector("#callus-phone-container");

    button.style.background = "var(--primary-color)";
    button.style.visibility = "visible";
    button.style.height = "55px";
    button.style.width = "100%";
    button.style.color = "white";
    button.style.borderRadius = "10px";
    button.style.display = "flex";
    button.style.justifyContent = "space-between";
    button.style.alignItems = "center";
    button.style.fontSize = "17px";
    button.style.fontWeight = "500";

    button.style.fontStyle = "normal";
    button.style.padding = "15px";

    buttonContainer.style.width = "100%";
    container.style.width = "100%";

    button.style.whiteSpace = "nowrap";
    button.style.flexDirection = "row-reverse";

    svg.style.width = "20px";
    svg.style.padding = "0";
    svg.style.stroke = "white";
    svg.style.fill = "none";

    if (typeof Weglot !== "undefined") {
      labelTranslate(Weglot.getCurrentLang());
    }
  };

  function verifierElementDomCharge(elementId) {
    const interval = setInterval(function () {
      const element = document.querySelector(elementId);
      const button = element.shadowRoot
        ? element.shadowRoot.querySelector("button")
        : null;

      if (button) {
        clearInterval(interval); // Arrête la vérification
        styleButton();
      }
    }, 200); // Vérifie tous les 100ms
  }
  verifierElementDomCharge("#wp-live-chat-by-3CX-phone");

  return (
    <call-us-phone
      style={{
        margin: "15px 20px",
        display: "flex",
        visibility: "hidden",
      }}
      id="wp-live-chat-by-3CX-phone"
      channel-url={callUsConf[slug].channelUrl}
      party={callUsConf[slug].partyId}
      // animation-style="slideUp"
      chat-delay="1000"
      enable="true"
      enable-onmobile="true"
      call-title="Contactez la réception"
    ></call-us-phone>
  );
}
