import React, { useCallback } from "react";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../checkin/inputErrorMessage";
import { EMAIL_PATTERN } from "../../../constants/checkinType";

export const CustomerInfo = ({
  send,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  email,
  room,
  handleRoomChange,
  setEmail,
  isEmailError,
  setIsEmailError,
  setIsShowCustomInfoPage,
}) => {
  /**
   * Handle changes in the email input field.
   * @param {Object} e - The event object.
   */
  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);

      if (!EMAIL_PATTERN.test(e.target.value)) {
        setIsEmailError(true);
      } else {
        setIsEmailError(false);
      }
    },
    [email, setEmail, setIsEmailError]
  );

  const handleGoBack = useCallback(
    (e) => {
      e.preventDefault();
      setIsShowCustomInfoPage(false);
    },
    [setIsShowCustomInfoPage]
  );

  return (
    <Row>
      {/* Back Button */}
      <div>
        <div>
          <Button
            onClick={handleGoBack}
            className="feedback-back-button feedback-button-text"
          >
            {`< Retour`}
          </Button>
        </div>

        {/* Label for customer information section */}
        <Label for="clientInfos" className="feedback-label-margin">
          <span className="feedback-label">Vos informations</span>
        </Label>
        <Row>
          <FormGroup className="form-group col">
            {/* Input box for last name */}
            <Label for="lastName">
              Nom<span className="gwPink">*</span>
              {send && !lastName && <InputErrorMessage />}
            </Label>
            <Input
              id="lastName"
              type="text"
              placeholder="Nom"
              value={lastName}
              onChange={handleLastNameChange}
              required
            />
          </FormGroup>
        </Row>

        {/* Input box for first name */}
        <Row>
          <FormGroup className="form-group col">
            <Label for="firstName">
              Prénom<span className="gwPink">*</span>
              {send && !firstName && <InputErrorMessage />}
            </Label>
            <Input
              id="firstName"
              type="text"
              placeholder="Prénom"
              value={firstName}
              onChange={handleFirstNameChange}
              required
            />
          </FormGroup>
        </Row>

        {/* Input box for room */}
        <Row>
          <FormGroup className="form-group col">
            <Label for="room">
              Room<span className="gwPink">*</span>
              {send && !room && <InputErrorMessage />}
            </Label>
            <Input
              id="room"
              type="text"
              placeholder="Room"
              value={room}
              onChange={handleRoomChange}
              required
            />
          </FormGroup>
        </Row>

        {/* Input box for email */}
        <Row>
          <FormGroup className="form-group col">
            <Label for="email">
              E-mail<span className="gwPink">*</span>
              {send && !email && <InputErrorMessage />}
              {send && email && isEmailError && (
                <InputErrorMessage message={"Erreur de format d'email"} />
              )}
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="Address email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </FormGroup>
        </Row>
      </div>
    </Row>
  );
};
