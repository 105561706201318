import { Outlet } from "react-router-dom";

import Sidebar from "../../components/menu/Sidebar";

const PageLayout = (props) => (
  <div className="home">
    {props.tabCount > 1 ? 
    <div className="col-12 col-lg-2 MenuContainer">
      <Sidebar />
    </div>
    : null }
    <div className="col-12 col-lg-10 Body">
      <Outlet />
    </div>
  </div>
);
export default PageLayout;
