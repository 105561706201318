import React from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../inputErrorMessage";

export const PlaceOfBirth = ({
  send,
  placeOfBirth,
  handlePlaceOfBirthChange,
}) => {
  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="placeOfBirth">
          Lieu de naissance<span className="gwPink">*</span>
          {send && !placeOfBirth && <InputErrorMessage />}
        </Label>
        <Input
          id="placeOfBirth"
          type="text"
          placeholder="Lieu de naissance"
          value={placeOfBirth}
          onChange={handlePlaceOfBirthChange}
          required
        />
      </FormGroup>
    </Row>
  );
};
