import React from "react";
import { FormGroup, Label, Row } from "reactstrap";
import { IDENTITY_VERIFICATION_MESSAGE } from "../../../../constants/checkinType";

export const IdentityVerificationMessage = () => {
  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="passport">
          <span style={{ fontWeight: "700", fontSize: "1.2rem" }}>
            {IDENTITY_VERIFICATION_MESSAGE}
          </span>
        </Label>
      </FormGroup>
    </Row>
  );
};
