/**
 * Return the description without any HTML tags
 * @param {*} value 
 * @returns string
 */

import {decodeHTML} from 'entities';

export const removeTags = (str) => {
    if ((str === null) || (str === '') || (str === undefined)) return '';
    else str = str.toString();
          
    const withoutTags = str.replace(/(<([^>]+)>)/ig, '');
    return decodeHTML(withoutTags)
  }
