import React, { useCallback, useEffect, useState } from "react";
import NotFound from "../NotFound";
import { Button, Col, Form, Spinner } from "reactstrap";
import { ConfirmModal } from "../../components/checkin/confirmModal";
import { FeedbackHeader } from "../../components/feedback/feedbackHeader";
import { Review } from "../../components/feedback/ review";
import { SatisfactionEnum } from "../../../constants/feedbackType";
import { CustomerInfo } from "../../components/feedback/customerInfo";
import { useInput } from "../../../lib/hooks";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Mood = ({ slug, hosterId }) => {
  // Get moodType from query
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const moodType = searchParams.get("moodType");
  // A trigger that checks if the submit button was pressed
  const [send, setSend] = useState(false);
  // Checker for confirm modal
  const [isConfirm, setIsConfirm] = useState(false);
  // Review
  const [satisfactionLevel, setSatisfactionLevel] = useState("");
  const [message, setMessage] = useState("");
  const [isShowCustomInfoPage, setIsShowCustomInfoPage] = useState(false);
  // Customer information
  const [firstName, , handleFirstNameChange] = useInput("");
  const [lastName, , handleLastNameChange] = useInput("");
  const [room, , handleRoomChange] = useInput("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [canMoveCustomPage, setCanMoveCustomPage] = useState(false);

  // set initial satisfaction level
  useEffect(() => {
    if (moodType) {
      setSatisfactionLevel(moodType);
    }
  }, [moodType, setSatisfactionLevel]);

  // Submit feedback
  const handleSubmit = useCallback(async () => {
    try {
      // Set 'send' to true
      setSend(true);

      // Protection for user information
      if (!firstName || !lastName || !email || !room || isEmailError) {
        return;
      }

      // disable button
      setButtonDisabled(true);

      // Post customer request
      const customerId = await axios
        .post(`${process.env.REACT_APP_API_URL}/customer`, {
          hoster: hosterId,
          firstname: firstName,
          lastname: lastName,
          phone: "",
          email: email,
          overwriting: false,
        })
        .then((response) => response.data._id);

      // Post feedback request
      await axios
        .post(`${process.env.REACT_APP_API_URL}/feedback`, {
          hoster: hosterId,
          customer: customerId,
          room,
          satisfactionLevel,
          message,
        })
        .then(() => {
          // Display confirm modal
          setIsConfirm(true);
        });
    } catch (error) {
      setButtonDisabled(false);
      console.log(error);
      return;
    }
  }, [
    send,
    setSend,
    setIsConfirm,
    firstName,
    lastName,
    email,
    room,
    isEmailError,
  ]);

  //Set state indicating whether or not to move to the customer information input page
  useEffect(() => {
    const canMoveCustomPage = !(
      satisfactionLevel !== SatisfactionEnum.SATISFIED && !message.trim()
    );
    setCanMoveCustomPage(canMoveCustomPage);
  }, [satisfactionLevel, setCanMoveCustomPage, message]);

  // Move to custom info page
  const moveToCustomInfoPage = useCallback(() => {
    if (canMoveCustomPage) {
      setIsShowCustomInfoPage(true);
    }
  }, [setIsShowCustomInfoPage, message, satisfactionLevel, canMoveCustomPage]);

  // Check if the feedback page can be shown
  const isShowFeedbackinPage = slug === "hotelsuede";

  return (
    <Col className="MessageFormMobile checkin">
      {isShowFeedbackinPage ? (
        <>
          {!isConfirm ? (
            <Form inline="true" className="infosForm">
              {/* Header */}
              <FeedbackHeader slug={slug} />
              {/* Review or Customer Info */}
              {isShowCustomInfoPage ? (
                <CustomerInfo
                  send={send}
                  firstName={firstName}
                  handleFirstNameChange={handleFirstNameChange}
                  lastName={lastName}
                  handleLastNameChange={handleLastNameChange}
                  email={email}
                  room={room}
                  handleRoomChange={handleRoomChange}
                  setEmail={setEmail}
                  isEmailError={isEmailError}
                  setIsEmailError={setIsEmailError}
                  setIsShowCustomInfoPage={setIsShowCustomInfoPage}
                />
              ) : (
                <Review
                  satisfactionLevel={satisfactionLevel}
                  setSatisfactionLevel={setSatisfactionLevel}
                  message={message}
                  setMessage={setMessage}
                />
              )}

              {/* Confirm button */}
              <div className="BtnContainer">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    if (isShowCustomInfoPage) {
                      handleSubmit();
                    } else {
                      moveToCustomInfoPage();
                    }
                  }}
                  className="checkin-button--color"
                  style={{
                    cursor: `${canMoveCustomPage ? "pointer" : "not-allowed"}`,
                  }}
                  disabled={isButtonDisabled}
                >
                  Valider
                  {isButtonDisabled ? <Spinner color="white" size="" /> : null}
                </Button>
              </div>
            </Form>
          ) : (
            <ConfirmModal slug={slug} feedbackType={"feedback"} />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </Col>
  );
};

export default Mood;
