import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Workbox } from "workbox-window";

// import 'bootstrap/dist/css/bootstrap.css';
import "./assets/scss/style.scss";
import "./assets/scss/style-HR.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// GTM Module
import TagManager from "react-gtm-module";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

// GTM Module
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox(`${process.env.PUBLIC_URL}/sw.js`, {scope: '/'});
    wb.register()
    .then(reg => console.log('service worker registered', reg))
    .catch(err => console.log('service worker failed', err));
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
