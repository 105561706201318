import Data from "../../components/service-sheets/data";

import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import { withRouter } from "../../../router/withRouter";
import axios from "axios";

import BtmSheet from "../../components/booking-form";
//import moment from 'moment'
import OrderForm from "../../components/service-sheets/orderForm";
import defaultImage from "../../../assets/images/default-service.jpg";
import OrderDetails from "../../components/service-sheets/orderDetails";
import { useParams } from "react-router-dom";
import { PriceContext } from "../../../App";
import priceType from "../../../constants/priceType";
import {
  getTextPostPrice,
  getTextPrePrice,
} from "../../../lib/TextPriceMethods";

const moment = require("moment-timezone");

const ServiceSheet = (props) => {
  //customer's informations
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState("");

  const [service, setService] = useState();
  const [quantity, setQuantity] = useState(1);
  const [delivery, setDelivery] = useState(moment.utc());
  const [price, setPrice] = useState({});
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [command, setCommand] = useState([]);
  const [orderSheets, setOrderSheets] = useState([]);
  const [ordering, setOrdering] = useState(false);
  const [validations, setValidations] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [slots, setSlots] = useState([]);
  const [beingProcessed, setBeingProcessed] = useState(false);

  const [isEmpty, setIsEmpty] = useState(true);

  let params = useParams();

  const handleNbSheetChange = async (event) => {
    setQuantity(event);
    if (quantity < event) {
      setCommand([...command, service]);
    } else {
      setCommand(command.slice(-1));
    }
  };
  const goBack = () => {
    window.history.back();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      lastname === undefined ||
      lastname.length === 0 ||
      firstname === undefined ||
      firstname?.length === 0 ||
      email === undefined ||
      email?.length === 0 ||
      details === undefined ||
      details?.length === 0 ||
      (phone?.length === 0 && props.router.params.slug === "latuiliere")
    ) {
      return;
    }
    if (beingProcessed) {
      return;
    }

    if (orderSheets.length > 0) {
      const customerData = {
        hoster: props.hosterId,
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        email: email,
        overwriting: firstname && lastname && phone && email ? true : false,
      };
      setBeingProcessed(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/customer`, customerData)
        .then(
          (response) => {
            console.log("customerData", customerData);
            console.log(orderSheets);
            const orderData = {
              hoster: props.hosterId,
              customer: response.data._id,
              orderDetails: details,
              deliveryDate: delivery,
              item: {
                originalProductId: service._id,
                quantity: quantity,
                sheets: orderSheets.map((sheet) => {
                  return {
                    items: sheet.items.map((item) => {
                      console.log("item", item);
                      return {
                        originalItemId: item._id,
                        quantity: item.quantity,
                        selectedPrecisions: item.selectedPrecisions,
                      };
                    }),
                  };
                }),
                type: "sheet",
              },
            };
            axios
              .post(`${process.env.REACT_APP_API_URL}/order`, orderData)
              .then(
                (response) => {
                  console.log("orderData", orderData);
                  setIsConfirm(true);
                  setBeingProcessed(true);
                },
                (error) => {
                  console.log(error);
                }
              );
          },
          (error) => {
            console.log(error);
            setBeingProcessed(true);
          }
        )
        .catch((error) => {
          setBeingProcessed(true);
        });
    }
  };

  const validDeliverySelectValue = (val) => {
    if (val) setDelivery(val);
  };

  const prevSheet = async () => {
    setStep(step - 1);
  };

  const nextSheet = async () => {
    setStep(step + 1);
  };

  const isFormValid = () => {
    return validations.reduce((prev, curr) => prev && curr, true);
  };

  const onFormValidate = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      if (step < command.length) {
        nextSheet();
      } else {
        setSuccess(true);
        onSuccess();
      }
    } else {
      setSuccess(false);
      scrollToError();
    }
  };

  const onSuccess = async () => {
    let order = {};
    let sheets = [];
    command.map((sheet_) => {
      let sheet = {};
      let items = [];
      sheet_.sections.map((section, x) => {
        section.items.map((item) => {
          if (item.selected) {
            items.push(item);
          }
        });
      });
      sheet.items = items;
      sheets.push(sheet);
      order.sheets = sheets;
    });
    setOrderSheets(sheets);
  };

  useEffect(() => {
    scrollToTotal();
  }, [orderSheets]);

  const scrollToTotal = async () => {
    document.getElementById("scrollToTotal").scrollIntoView();
  };
  const scrollToHeadOfSheet = async () => {
    document.getElementById("sheet-title").scrollIntoView();
  };

  const scrollToError = () => {
    document.getElementById("Alert-errorMsg").scrollIntoView();
  };

  const getOrderTotal = (_order) => {
    let addedPrice = 0;
    _order.forEach((sheet) => {
      sheet.sections.forEach((section) => {
        section.items.forEach((item) => {
          if (item.selected === true) {
            addedPrice = addedPrice + item.price * item.quantity;
            item.selectedPrecisions?.forEach((person) => {
              person.precisions?.forEach((precision) => {
                console.log("precision", precision);
                addedPrice += precision.price;
              });
            });
          }
        });
      });
    });
    const initialPrice =
      service?.price?.type === priceType.TEXT ? 0 : service?.price?.value;
    return {
      ...price,
      value: Math.round((initialPrice * quantity + addedPrice) * 100) / 100,
    };
  };

  const isOrdering = (order) => {
    let isOrdering = false;
    order.forEach((sheet) => {
      sheet.sections.forEach((section) => {
        section.items.forEach((item) => {
          isOrdering = isOrdering || item.quantity > 0;
        });
      });
    });
    return isOrdering;
  };

  useEffect(() => {
    setPrice(getOrderTotal(command));
    setOrdering(isOrdering(command));
  }, [command]);

  const editSheet = (index) => {
    setEditing(true);
    setStep(index);
    setSuccess(false);
  };

  const sortSlots = (slots) => {
    return slots.sort((elt1, elt2) => {
      if (elt1.start < elt2.start) return -1;
      if (elt1.start > elt2.start) return 1;
      return 0;
    });
  };

  const defineSlots = () => {
    let slotsDefined = [];

    let res = null;
    let slots = [];

    for (let i = 0; i < service.delivery.timeslots.length; i++) {
      res = generateSlots(
        service.delivery.timeslots[i].start,
        service.delivery.timeslots[i].end,
        service.delivery.interval
      );
      slots = slots.concat(res);
    }
    slotsDefined = getValidSlots(slots, service.delivery.delayBeforeDelivery);
    setSlots(slotsDefined);
  };

  const getValidSlots = (tab, delay) => {
    const maxStart = moment().utc().add(delay, "m");
    const res = tab.map((element) => {
      if (moment(element.start).isBefore(maxStart)) {
        return {
          start: moment(element.start).add(1, "day").toDate().toISOString(),
          end: moment(element.end).add(1, "day").toDate().toISOString(),
        };
      } else {
        return element;
      }
    });
    return sortSlots(res);
  };

  const generateSlots = (start, end, interval) => {
    const nowYear = moment().utc().year();
    const nowMonth = moment().utc().month();
    const nowDate = moment().utc().date();

    let slots = [];
    let startSlot = moment(start);
    let endSlot = moment(start).add(interval, "m");
    let slot = {};

    do {
      slot = {
        start: moment(startSlot)
          .set("year", nowYear)
          .set("month", nowMonth)
          .set("date", nowDate)
          .set("seconds", 0)
          .set("milliseconds", 0)
          .toDate()
          .toISOString(),
        end: moment(endSlot)
          .set("year", nowYear)
          .set("month", nowMonth)
          .set("date", nowDate)
          .set("seconds", 0)
          .set("milliseconds", 0)
          .toDate()
          .toISOString(),
      };
      slots.push(slot);
      startSlot = moment(endSlot);
      endSlot = moment(startSlot).add(interval, "m");
    } while (endSlot.isSameOrBefore(moment(end).utc()));
    return slots;
  };

  const getFormSheet = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/product/item/" + params.formId)
      .then((response) => {
        setService(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    //callback function scroll to 1st element of sheet
    if (step > 1) {
      scrollToHeadOfSheet();
    }
  }, [step]);

  useEffect(() => {
    getFormSheet();
  }, [params]);

  useEffect(() => {
    if (service !== undefined) {
      setPrice(service.price);
      setCommand([service]);
      defineSlots();
    }
  }, [service]);

  useEffect(() => {
    let checker = validations.filter((v) => v === true).length;
    setIsEmpty(checker >= 1 ? false : true);
  });

  return (
    <div className="order">
      {isConfirm ? (
        <div className="orderAlert">
          <div className="success-box">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <p>Merci pour votre réservation !</p>
            <p>
              Nous vous contacterons au plus vite pour personnaliser votre
              demande.
            </p>
            <Button className="customBtn btn-blue" href="../services">
              Ok
            </Button>
          </div>
        </div>
      ) : (
        <div className="Service ServiceSheet">
          <button className="back" onClick={goBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <span>Nos services </span>
          </button>
          <div className="bbody container">
            <Row className="m-0">
              {service?.photo === undefined ? (
                <figure className="cardImg-top"></figure>
              ) : (
                <figure
                  className="cardImg-top"
                  style={{
                    backgroundImage: `url(${
                      service.photo ? service.photo : defaultImage
                    })`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></figure>
              )}

              <Row className="contentService">
                <Col className="sectionService col-lg-7">
                  <div className="infoService">
                    <Row className="headerService">
                      <Col>
                        <div className="title">{service?.label}</div>
                      </Col>
                    </Row>
                    {/* <Row> */}
                    <div className="itemSheetDetail">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: service?.description,
                        }}
                      />
                      <br />
                      {service?.condition ? (
                        <div className="conditions">
                          <h5>Conditions</h5>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: service.condition,
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {success ? (
                    <Row className="OrderSummary">
                      <hr />
                      <div className="main">
                        <h5>Votre sélection</h5>
                        {orderSheets.map((sheet, index) => {
                          return (
                            <OrderDetails
                              service={service}
                              quantity={quantity}
                              count={sheet}
                              price={price}
                              index={index + 1}
                              editSheet={editSheet}
                              key={`OrderDetails-${index}`}
                              setEditing={setEditing}
                            />
                          );
                        })}
                      </div>
                      <table className="sheet_total">
                        <tfoot id="scrollToTotal">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" className="text-right">
                              Total :
                            </th>
                            <th scope="col" className="text-right">
                              <PriceContext.Consumer>
                                {(value) => (
                                  <>{value({ value: price.value })}</>
                                )}
                              </PriceContext.Consumer>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </Row>
                  ) : (
                    <Row className="orderForm">
                      <hr />
                      {service?.recurring > 1 ? (
                        <Row className="quantity">
                          <Col>
                            <Label for="quantity">
                              {service.recurringLabel
                                ? service.recurringLabel
                                : "Quantité"}{" "}
                              <span className="gwPink">*</span>
                            </Label>
                          </Col>
                          <Col>
                            {" "}
                            <FormGroup className="form-group">
                              <div className="quantitySpinner">
                                <InputSpinner
                                  type={"int"}
                                  precision={1}
                                  name={"quantity"}
                                  id="quantity"
                                  max={service.recurring}
                                  min={1}
                                  step={1}
                                  value={quantity}
                                  onChange={(event) => {
                                    handleNbSheetChange(event);
                                  }}
                                  variant={"light"}
                                  size="sm"
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                      <div className="serviceSheets">
                        <Form>
                          {command.map((resa, index) => {
                            if (index + 1 === step) {
                              return (
                                <>
                                  <Row
                                    className="sheet"
                                    key={`reservation-${index}`}
                                  >
                                    <h5
                                      className="sheet-title"
                                      id="sheet-title"
                                    >
                                      Sélection{" "}
                                      {quantity > 1 ? `n°${index + 1}` : null} -{" "}
                                      {resa.label}
                                    </h5>
                                    {resa.sections.map((section, index) => {
                                      if (section.items.length > 0) {
                                        return (
                                          <OrderForm
                                            comm={command}
                                            currentCommande={step - 1}
                                            indexSection={index}
                                            setCommand={setCommand}
                                            step={step}
                                            section={section}
                                            validations={validations}
                                            editing={editing}
                                            key={`OrderForm-${index}`}
                                          />
                                        );
                                      }
                                    })}
                                  </Row>
                                  <Row className="buttonSheet">
                                    {step > 1 ? (
                                      <Col>
                                        <Button onClick={(e) => prevSheet()}>
                                          Revenir au {service?.label} n° {index}
                                        </Button>
                                      </Col>
                                    ) : null}
                                    <Col>
                                      <Button
                                        style={{
                                          cursor: isFormValid()
                                            ? "pointer"
                                            : "not-allowed",
                                        }}
                                        onClick={(e) => {
                                          if (isEmpty === false) {
                                            onFormValidate(e);
                                          }
                                        }}
                                        className={
                                          step < command.length
                                            ? ""
                                            : "customBtn"
                                        }
                                      >
                                        {step < command.length
                                          ? `Passer au ${service?.label.toLowerCase()} n°${
                                              index + 2
                                            }`
                                          : "Valider ma commande"}
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              );
                            }
                          })}
                        </Form>
                      </div>
                    </Row>
                  )}
                </Col>
                <Col className="bookingForm">
                  {success ? (
                    <>
                      <span className="title">Dernière étape</span>
                      <Form
                        inline
                        className="infosForm needs-validation"
                        onSubmit={onSubmit}
                      >
                        <h5>Ma réservation</h5>
                        {slots.length >= 1 ? (
                          <FormGroup className="form-group col">
                            <Col sm={12}>
                              <Label for="delivery">
                                Heure de livraison souhaitée{" "}
                                <span className="gwPink">*</span>
                              </Label>
                            </Col>
                            <Col sm={12}>
                              <Input
                                id="delivery"
                                name="delivery"
                                placeholder="Selectionnez un horaire"
                                type="select"
                                required
                                value={delivery}
                                onChange={(event) =>
                                  setDelivery(event.target.value)
                                }
                              >
                                <option value="">
                                  Selectionnez un horaire
                                </option>
                                {slots.map((slot, index) => {
                                  const startSlotFormatted = moment(slot.start)
                                    .tz("Europe/Paris")
                                    .format(
                                      "[le] D/MM/YYYY [entre] H[h]mm "
                                      // 'H[h]mm'
                                    );
                                  const endSlotFormatted = moment(slot.end)
                                    .tz("Europe/Paris")
                                    .format(
                                      // '[le] D/MM/YYYY [à] H[h]mm '
                                      "H[h]mm "
                                    );
                                  return (
                                    <option
                                      key={`slot-${index}`}
                                      value={moment(slot.start).format()}
                                    >{`${startSlotFormatted} et ${endSlotFormatted}`}</option>
                                  );
                                })}
                              </Input>
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup className="form-group col">
                          <Label for="room">
                            Détails <span className="gwPink">*</span>
                          </Label>
                          <Input
                            id="details"
                            name="details"
                            placeholder="Chambre, allergies ..."
                            type="text"
                            value={details}
                            onChange={(event) => setDetails(event.target.value)}
                            required={true}
                          />
                        </FormGroup>
                        <h5>Mes informations</h5>
                        <Row>
                          <FormGroup className="form-group col">
                            <Label for="lastname">
                              Nom <span className="gwPink">*</span>
                            </Label>
                            <Input
                              id="lastname"
                              name="lastname"
                              placeholder="Exemple : Dupont"
                              type="text"
                              required={true}
                              value={lastname}
                              onChange={(event) =>
                                setLastname(event.target.value)
                              }
                            />
                          </FormGroup>

                          <FormGroup className="form-group col">
                            <Label for="firstname">
                              Prénom <span className="gwPink">*</span>
                            </Label>
                            <Input
                              id="firstname"
                              name="firstname"
                              placeholder="Exemple : Jean"
                              type="text"
                              required={true}
                              value={firstname}
                              onChange={(event) =>
                                setFirstname(event.target.value)
                              }
                            />
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup className="form-group col">
                            <Label for="email">
                              E-mail <span className="gwPink">*</span>
                            </Label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="jeandupont@gmail.com"
                              type="email"
                              required={true}
                              value={email}
                              onChange={(event) => setEmail(event.target.value)}
                            />
                          </FormGroup>
                          {props.router.params.slug === "latuiliere" ? (
                            <FormGroup className="form-group col">
                              <Label for="room">
                                N° de téléphone{" "}
                                <span className="gwPink">*</span>
                              </Label>
                              <Input
                                id="phone"
                                name="phone"
                                placeholder="0123456489"
                                type="text"
                                required={true}
                                value={phone}
                                onChange={(event) =>
                                  setPhone(event.target.value)
                                }
                              />
                            </FormGroup>
                          ) : null}
                        </Row>
                        <div className="BtnContainer">
                          <Button
                            type="submit"
                            className="customBtn btn-blue smooth-scroll"
                            // onClick={onSubmit}
                            disabled={beingProcessed}
                          >
                            {beingProcessed ? (
                              <Spinner color="secondary" size="" />
                            ) : null}
                            <span>Valider ma commande</span>
                          </Button>
                        </div>
                      </Form>
                    </>
                  ) : service?.price.type === priceType.TEXTPRICE ||
                    service?.price.type === priceType.PRICE ? (
                    price.value > 0 ? (
                      <>
                        <div className="price-infos">
                          {ordering === false ? (
                            <>
                              <i className="text-left">
                                {getTextPrePrice(price.txtPrePrice)}
                              </i>{" "}
                              <PriceContext.Consumer>
                                {(value) => (
                                  <>{value({ value: price.value })}</>
                                )}
                              </PriceContext.Consumer>{" "}
                              <i>{getTextPostPrice(price.txtPostPrice)}</i>
                            </>
                          ) : (
                            <PriceContext.Consumer>
                              {(value) => <>{value({ value: price.value })}</>}
                            </PriceContext.Consumer>
                          )}
                        </div>
                        <div className="infos">
                          <i>Vous n'avez rien à débourser maintenant.</i>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        <i>Faites votre sélection.</i>
                      </>
                    )
                  ) : (
                    <>
                      {ordering === false ? (
                        <>
                          <div className="price-infos">
                            <PriceContext.Consumer>
                              {(value) => (
                                <>
                                  {value({
                                    value: price.txtPrice,
                                    price: price,
                                  })}
                                </>
                              )}
                            </PriceContext.Consumer>
                          </div>
                          <div className="infos"></div>{" "}
                        </>
                      ) : (
                        <PriceContext.Consumer>
                          {(value) => <>{value({ value: price.value })}</>}
                        </PriceContext.Consumer>
                      )}
                      <i>Vous n'avez rien à débourser maintenant.</i>
                    </>
                  )}
                </Col>
              </Row>
            </Row>
            <BtmSheet
              header={
                <>
                  <Col className="detailPrice col">
                    {price.type === priceType.PRICE ||
                    price.type === priceType.TEXTPRICE ? (
                      ordering === false ? (
                        <>
                          <i>{getTextPrePrice(price.txtPrePrice)}</i>
                          <PriceContext.Consumer>
                            {(value) => (
                              <>
                                {value({ value: price.value, style: "noFree" })}
                              </>
                            )}
                          </PriceContext.Consumer>
                          <i>{getTextPostPrice(price.txtPostPrice)}</i>
                        </>
                      ) : (
                        <PriceContext.Consumer>
                          {(value) => (
                            <>
                              {value({ value: price.value, style: "noFree" })}
                            </>
                          )}
                        </PriceContext.Consumer>
                      )
                    ) : ordering === false ? (
                      <PriceContext.Consumer>
                        {(value) => (
                          <>
                            {value({
                              value: price.txtPrice,
                              style: "noFree",
                              price: price,
                            })}
                          </>
                        )}
                      </PriceContext.Consumer>
                    ) : (
                      <PriceContext.Consumer>
                        {(value) => (
                          <>{value({ value: price.value, style: "noFree" })}</>
                        )}
                      </PriceContext.Consumer>
                    )}
                  </Col>
                  <Col id="info">
                    <div className="infos">
                      <i>
                        Vous n'avez rien à <br /> débourser maintenant.
                      </i>
                    </div>
                  </Col>
                  {!success ? (
                    <Col>
                      <Button
                        style={{
                          cursor: isEmpty === false ? "pointer" : "not-allowed",
                        }}
                        onClick={(e) => {
                          if (isEmpty === false) {
                            onFormValidate(e);
                          }
                        }}
                        className={step < command.length ? "" : "customBtn"}
                      >
                        {step < command.length
                          ? `Passer au ${service?.label.toLowerCase()} suivant`
                          : "Valider"}
                      </Button>
                    </Col>
                  ) : null}
                </>
              }
              hosterType={props.hosterType}
              visible={success}
            >
              {success ? (
                <Col className="bookingFormMobile">
                  <span className="title">Réserver ma prestation</span>
                  <Form
                    inline
                    className="infosForm needs-validation"
                    onSubmit={onSubmit}
                  >
                    <h5>Ma réservation</h5>
                    {/* <Row> */}
                    {slots.length > 1 ? (
                      <FormGroup className="form-group col">
                        <Col sm={12}>
                          <Label for="delivery">
                            Heure de livraison souhaitée{" "}
                            <span className="gwPink">*</span>
                          </Label>
                        </Col>
                        <Col sm={12}>
                          <Input
                            id="delivery"
                            name="delivery"
                            placeholder="5h"
                            type="select"
                            required={true}
                            value={delivery}
                            onChange={(event) =>
                              setDelivery(event.target.value)
                            }
                          >
                            <option value="">Selectionnez un horaire</option>
                            {slots.map((slot, index) => {
                              const startSlotFormatted = moment(slot.start)
                                .tz("Europe/Paris")
                                .format(
                                  "[le] D/MM/YYYY [entre] H[h]mm "
                                  // 'H[h]mm'
                                );
                              const endSlotFormatted = moment(slot.end)
                                .tz("Europe/Paris")
                                .format(
                                  // '[le] D/MM/YYYY [à] H[h]mm '
                                  "H[h]mm "
                                );
                              return (
                                <option
                                  key={`slot-${index}`}
                                  value={moment(slot.start).format()}
                                >{`${startSlotFormatted} et ${endSlotFormatted}`}</option>
                              );
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                    ) : null}
                    <FormGroup className="form-group col">
                      <Label for="room">
                        Détails <span className="gwPink">*</span>
                      </Label>
                      <Input
                        id="details"
                        name="details"
                        placeholder="Chambre, allergies ..."
                        type="text"
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        required={true}
                      />
                    </FormGroup>
                    {/* </Row> */}

                    <h5>Mes informations</h5>
                    <Row>
                      <FormGroup className="form-group col">
                        <Label for="lastname">
                          Nom <span className="gwPink">*</span>
                        </Label>
                        <Input
                          id="lastname"
                          name="lastname"
                          placeholder="Exemple : Dupont"
                          type="text"
                          required={true}
                          value={lastname}
                          onChange={(event) => setLastname(event.target.value)}
                        />
                      </FormGroup>

                      <FormGroup className="form-group col">
                        <Label for="firstname">
                          Prénom <span className="gwPink">*</span>
                        </Label>
                        <Input
                          id="firstname"
                          name="firstname"
                          placeholder="Exemple : Jean"
                          type="text"
                          required={true}
                          value={firstname}
                          onChange={(event) => setFirstname(event.target.value)}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="form-group col">
                        <Label for="email">
                          E-mail <span className="gwPink">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          placeholder="jeandupont@gmail.com"
                          type="email"
                          required={true}
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </FormGroup>
                      {props.router.params.slug === "latuiliere" ? (
                        <FormGroup className="form-group col">
                          <Label for="room">
                            N° de téléphone <span className="gwPink">*</span>
                          </Label>
                          <Input
                            id="phone"
                            name="phone"
                            placeholder="0123456489"
                            type="text"
                            required={true}
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                          />
                        </FormGroup>
                      ) : null}
                    </Row>

                    <div className="BtnContainer">
                      {/* <Col> */}
                      <Button
                        type="submit"
                        className="customBtn btn-blue smooth-scroll"
                        // onClick={onSubmit}
                        disabled={beingProcessed}
                      >
                        {beingProcessed ? (
                          <Spinner color="secondary" size="" />
                        ) : null}
                        <span>Valider ma commande</span>
                      </Button>
                      {/* </Col> */}
                    </div>
                  </Form>
                </Col>
              ) : null}
            </BtmSheet>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(ServiceSheet);
