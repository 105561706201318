import React, { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { FormGroup, Label, Row } from "reactstrap";
import ReactCountryFlag from "react-country-flag";
function CountrySelector({ send, nation, setNation }) {
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setNation(value);
  };

  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="nation">
          Nationalité<span className="gwPink">*</span>
          {send && !nation && <InputErrorMessage />}
          <ReactCountryFlag
            countryCode={nation.value}
            style={{ marginLeft: "0.5rem" }}
            className="country-flag"
          />
        </Label>
        <div id="nation" className="form-control country">
          <Select options={options} value={nation} onChange={changeHandler} />
        </div>
      </FormGroup>
    </Row>
  );
}

export default CountrySelector;
