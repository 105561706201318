import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { PriceContext } from "../../../App";

const OrderDetails = (props) => {
  const [count, setCount] = useState(props.count);
  const [service, setService] = useState(props.service);

  return (
    <>
      <Row className="orderDetail_label">
        <Col md={9}>
          <span>
            {service.label} n°{props.index}
          </span>
        </Col>
        <Col className="text-right">
          {service.price.value > 0 ? (
            <PriceContext.Consumer>
              {(value) => (
                <>
                  {value({ value: service.price.value, price: service.price })}
                </>
              )}
            </PriceContext.Consumer>
          ) : null}
        </Col>
      </Row>
      <input
        type="button"
        className="BtnEditSheet"
        onClick={(e) => props.editSheet(props.index)}
        value="Modifier"
      />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">quantité</th>
            <th scope="col">description</th>
            <th scope="col" className="text-right">
              prix
            </th>
          </tr>
        </thead>
        <tbody>
          {count.items.map((val, index) => {
            return (
              <>
                <tr key={`item-${index}`}>
                  <td className="text-left">
                    {val.quantity ? val.quantity : 1}
                  </td>
                  <td className="text-left">{val.label}</td>
                  <td className="text-right">
                    {val.price === 0 ? (
                      "inclus"
                    ) : (
                      <PriceContext.Consumer>
                        {(value) => (
                          <>
                            {value({
                              value:
                                val.price * (val.quantity ? val.quantity : 1),
                            })}
                          </>
                        )}
                      </PriceContext.Consumer>
                    )}
                  </td>
                </tr>
                {val.selectedPrecisions && val.selectedPrecisions.length > 0
                  ? val.selectedPrecisions.map((person, id) => {
                      return (
                        <>
                          {person.precisions.length > 0 ? (
                            <tr
                              key={`precisions-title-${id}`}
                              className="product-precision-table-line"
                            >
                              <td className="text-left"></td>
                              <td className="text-left">
                                Choix {id + 1 + " / " + val.quantity}:
                              </td>
                              <td className="text-right"></td>
                            </tr>
                          ) : null}
                          {person.precisions.map((precision, i) => {
                            return (
                              <tr
                                key={`precisions-${i}`}
                                className="product-precision-table-line"
                              >
                                <td className="product-resume-precisions-choices-line text-left"></td>
                                <td className="product-resume-precisions-choices-line text-left">
                                  <li className="w-75 product-resume-precisions-choices">
                                    {precision.name + ": " + precision.choice}
                                  </li>
                                </td>
                                <td className="product-resume-precisions-choices-line text-right">
                                  {precision.price === 0 ? (
                                    ""
                                  ) : (
                                    <PriceContext.Consumer>
                                      {(value) => (
                                        <>
                                          {value({
                                            value: precision.price,
                                          })}
                                        </>
                                      )}
                                    </PriceContext.Consumer>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })
                  : null}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default OrderDetails;
