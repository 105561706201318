import React, { useCallback, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export const EstimatedArrivalTime = ({
  estimatedArrivalTime,
  setEstimatedArrivalTime,
}) => {
  const hours = Array.from({ length: 24 }, (_, hour) => {
    return hour < 10 ? `0${hour}` : `${hour}`;
  });
  const minutes = Array.from({ length: 4 }, (_, index) => {
    const minute = index * 15;
    return minute < 10 ? `0${minute}` : `${minute}`;
  });

  const handleHourChange = useCallback(
    (event) => {
      setEstimatedArrivalTime((currentTime) => {
        return { ...currentTime, hour: event.target.value };
      });
    },
    [setEstimatedArrivalTime]
  );

  const handleMinuteChange = useCallback(
    (event) => {
      setEstimatedArrivalTime((currentTime) => {
        return { ...currentTime, minute: event.target.value };
      });
    },
    [setEstimatedArrivalTime]
  );

  return (
    <Row>
      <FormGroup className="form-group col">
        <Col>
          <Label for="estimatedArrivalTime">
            Heure d'arrivée estimée<span className="gwPink">*</span>
          </Label>
        </Col>
        <Col>
          <Col className="CustomTimePicker">
            <FormGroup className="form-group flex">
              <Input
                type="select"
                value={estimatedArrivalTime.hour}
                onChange={handleHourChange}
                className="text"
              >
                {hours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                value={estimatedArrivalTime.minute}
                onChange={handleMinuteChange}
                className="text"
              >
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Col>
      </FormGroup>
    </Row>
  );
};
