import React from "react";
import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
import GoogleMapReact from "google-map-react";
import { max } from "moment";

const AnyReactComponent = ({ text }) => (
  <div className="place_marker" id={text}>
    <IconSpot />
    <span>{text}</span>
  </div>
);

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: props.hoster.hosterLat,
        lng: props.hoster.hosterLong,
      },
      zoom: 14,
      width: 450
    };
    this.centerMap = this.centerMap.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount = () => {
    const partnerLat = (this.props.activity === undefined) ? undefined : this.props.activity.activityLat
    const partnerLng = (this.props.activity === undefined) ? undefined : this.props.activity.activityLong

    this.setState({center: this.centerMap(
      {hosterLat: this.props.hoster.hosterLat, hosterLng: this.props.hoster.hosterLong},
      {partnerLat: partnerLat, partnerLng: partnerLng}
    )})

    this.setState({zoom: this.getZoomFactor(
      {hosterLat: this.props.hoster.hosterLat, hosterLng: this.props.hoster.hosterLong},
      {partnerLat: partnerLat, partnerLng: partnerLng}
    )})
  }

  /**
   * Explanation: https://en.wikipedia.org/wiki/Haversine_formula
   * @param {*} lat1 
   * @param {*} lon1 
   * @param {*} lat2 
   * @param {*} lon2 
   * @returns 
   */
  measureDistance = (lat1, lon1, lat2, lon2) => {
      var R = 6378.137; // Radius of earth in KM
      var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
      var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d * 1000; // meters
  }

  zoomFactorByMeter = (latitude, distanceMeters, pixelWidth) => {
    // Google formula to know the nb of meters by pixels : 
    // meters = 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoomFactor)
    const pixelsMargin = 50
    const pixels = pixelWidth - 2 * pixelsMargin
    const zoomFactor = Math.log2((156543.03392 * Math.cos(latitude * Math.PI / 180)) / (distanceMeters / pixels))
    return Math.floor(zoomFactor)
  }

  getZoomFactor = ({hosterLat, hosterLng}, {partnerLat, partnerLng}) => {
    if ((partnerLat === undefined) || (partnerLng === undefined)) return 16

    const distance1 = this.measureDistance(hosterLat, hosterLng, partnerLat, hosterLng)
    const distance2 = this.measureDistance(hosterLat, hosterLng, hosterLat, partnerLng)

    return this.zoomFactorByMeter(hosterLat, Math.max(distance1, distance2), this.state.width)
  }

  centerMap = ({hosterLat, hosterLng}, {partnerLat, partnerLng}) => {
    if ((partnerLat === undefined) || (partnerLng === undefined)) {
      return {
        lat: hosterLat,
        lng: hosterLng
      }
    }
    const centerLat = hosterLat - (hosterLat - partnerLat) / 2
    const centerLng = partnerLng + (hosterLng - partnerLng) / 2
    
    return {lat: centerLat, lng: centerLng}
  }

  render() {
    return (
      <div style={{ height: "450px !important",  width: "100%" }} className="googlemap">
        <a href={this.props.activity ? `https://www.google.fr/maps/dir/${this.props.hoster ? `${this.props.hoster.hosterLat},${this.props.hoster.hosterLong}` : ""}/${this.props.activity.activityLat},${this.props.activity.activityLong}/` : this.props.googlemap_link ? this.props.googlemap_link : `https://maps.google.com/maps?q=loc:${this.props.hoster ? `${this.props.hoster.hosterLat},${this.props.hoster.hosterLong}` : ""}` } target="_blank" rel="noreferrer" >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_PUBLIC_API_KEY,
            disableDefaultUI: true,
          }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          {this.props.activity ? 
          <AnyReactComponent
            lat={this.props.activity.activityLat}
            lng={this.props.activity.activityLong} 
            text={this.props.activity.activityName}
          /> : null }

          {this.props.hoster ? (
            <AnyReactComponent
              lat={this.props.hoster.hosterLat}
              lng={this.props.hoster.hosterLong}
              text={this.props.hoster.hosterName}
            />
          ) : null}
        </GoogleMapReact>
        </a>
      </div>
    );
  }
}
