import React from "react";

import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import axios from "axios";
import { withRouter } from "../../../router/withRouter";

import { ReactComponent as IconBed } from "../../../assets/images/icon-bed.svg";
import { ReactComponent as IconReception } from "../../../assets/images/icon-reception.svg";
import { ReactComponent as IconRestaurant } from "../../../assets/images/icon-restaurant.svg";

import { ReactComponent as IconTimeLimit } from "../../../assets/images/icon-timeLimit.svg";
import BtmSheet from "../../components/booking-form";

import defaultImage from "../../../assets/images/default-service.jpg";
import { PriceContext } from "../../../App";

import orderabilityType from "../../../constants/orderabilityType";
import priceType from "../../../constants/priceType";
import {
  getTextPostPrice,
  getTextPrePrice,
} from "../../../lib/TextPriceMethods";
import ServiceType from "../../../constants/serviceType";
import { Navigate } from "react-router-dom";
class ServiceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.getServiceInfos = this.getServiceInfos.bind(this);
    this.setClientData = this.setClientData.bind(this);
    this.state = {
      lastname: "",
      firstname: "",
      email: "",
      phone: "",
      details: "",
      quantity: 1,
      service: {},
      price: {},
      orderability: {},
      isConfirm: false,
      beingProcessed: false,
      service_id: this.props.router.params.id,
    };
  }

  getServiceInfos = async () => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + "/product/item/" + this.state.service_id
      )
      .then((response) => {
        this.setState({
          service: response.data,
          price: response.data.price,
          orderability: response.data.orderability,
        });
      })
      .catch((error) => {
        console.log(error, "ERROR");
      });
  };

  async componentDidMount() {
    await this.getServiceInfos();
    console.log("service", this.state.orderability);
  }

  setClientData = () => {
    const customerData = {
      hoster: this.props.hosterId,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone,
      email: this.state.email,
      overwriting: firstname && lastname && phone && email ? true : false,
    };
    this.setState({ beingProcessed: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/customer`, customerData)
      .then((response) => {
        const data = {
          hoster: this.props.hosterId,
          customer: response.data._id,
          orderDetails: this.state.details,
          item: {
            originalProductId: this.state.service._id,
            quantity: this.state.quantity,
            type: "single",
          },
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/order`, data)
          .then(
            (response) => {
              this.setState({ isConfirm: true });
              this.setState({ beingProcessed: false });
            },
            (error) => {
              this.setState({ beingProcessed: false });
              console.log(error);
            }
          )
          .catch((error) => {
            this.setState({ beingProcessed: false });
          });
      });
  };

  handleChange = async (event) => {
    var value = event.target.value;
    var name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  handleQuantityChange = async (event) => {
    this.setState({
      quantity: event,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.beingProcessed) {
      this.setClientData();
    }
  };

  displayDescription = (value) => {
    if (!value) return;
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  };

  displayButtonText = () => {
    const who = this.props.hosterType === "Gite" ? "le gîte" : "la réception";
    if (
      this.state.orderability.type === "orderable" ||
      this.state.orderability.type === "redirection"
    ) {
      return this.state.orderability.labelButton !== ""
        ? this.state.orderability.labelButton
        : `J'informe ${who} de ma demande`;
    } else {
      return `${this.state.orderability.labelButton}`;
    }
  };

  goBack() {
    window.history.back();
  }

  render() {
    if (this.state.service.type === ServiceType.SHEET)
      return (
        <Navigate
          to={`/${this.props.router.params.slug}/form/${this.state.service_id}`}
          replace
        />
      );
    else
      return (
        <div className="order">
          {this.state.isConfirm ? (
            <div className="orderAlert">
              <div className="success-box">
                <div className="success-checkmark">
                  <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                  </div>
                </div>
                <p>Merci pour votre réservation !</p>
                <p>
                  Nous vous contacterons au plus vite pour personnaliser votre
                  demande.
                </p>
                <Button className="customBtn btn-blue" href="../services">
                  Ok
                </Button>
              </div>
            </div>
          ) : this.state.price ? (
            <>
              <div className="Service">
                <button className="back" onClick={this.goBack}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  <span>Nos services </span>
                </button>
                <div className="bbody container">
                  <Row className="m-0">
                    {/* <div className="box-shadow"> */}
                    {this.state.service.photo === undefined ? (
                      <figure className="cardImg-top"></figure>
                    ) : (
                      <figure
                        className="cardImg-top"
                        style={{
                          backgroundImage: `url(${
                            this.state.service.photo
                              ? this.state.service.photo
                              : defaultImage
                          })`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></figure>
                    )}

                    <Row className="contentService">
                      <Col className="sectionService col-lg-7">
                        <div className="infoService">
                          <div className="headerService">
                            <Col md={9}>
                              <div className="title">
                                {this.state.service.label}
                              </div>
                            </Col>
                            <Col className="detailPrice">
                              {this.state.price.type === priceType.TEXT ? (
                                <PriceContext.Consumer>
                                  {(value) => (
                                    <>
                                      {value({
                                        value: this.state.price?.txtPrice,
                                        price: this.state.price,
                                      })}
                                    </>
                                  )}
                                </PriceContext.Consumer>
                              ) : (
                                <PriceContext.Consumer>
                                  {(value) => (
                                    <>
                                      {value({
                                        value: this.state.price?.value,
                                        price: this.state.price,
                                      })}
                                    </>
                                  )}
                                </PriceContext.Consumer>
                              )}
                            </Col>
                          </div>
                          {/* <Row> */}
                          {this.state.price.type === priceType.TEXTPRICE &&
                          (this.state.price.txtPrePrice !== "none" ||
                            this.state.price.txtPostPrice !== "none") ? (
                            <i className="asterisk-details">
                              <p>
                                {getTextPrePrice(this.state.price.txtPrePrice)}
                                <PriceContext.Consumer>
                                  {(value) => (
                                    <>
                                      {value({
                                        value: this.state.price.value,
                                        price: this.state.price,
                                        style: "inline",
                                      })}
                                    </>
                                  )}
                                </PriceContext.Consumer>{" "}
                                {getTextPostPrice(
                                  this.state.price.txtPostPrice
                                )}
                              </p>
                            </i>
                          ) : null}
                          <div className="itemDetail">
                            <span>
                              {this.displayDescription(
                                this.state.service.description
                              )}
                            </span>
                            <br />
                            <div className="recap">
                              <Row>
                                <Col md={12} lg={6} className="">
                                  {this.state.service.supply === "inRoom" ? (
                                    <span>
                                      <figure>
                                        <IconBed />
                                      </figure>{" "}
                                      livré dans votre chambre
                                    </span>
                                  ) : null}
                                  {this.state.service.supply ===
                                  "inRestaurant" ? (
                                    <span>
                                      <figure>
                                        <IconRestaurant />
                                      </figure>{" "}
                                      à récupérer au restaurant
                                    </span>
                                  ) : null}
                                  {this.state.service.supply ===
                                  "atReception" ? (
                                    <span>
                                      <figure>
                                        <IconReception />
                                      </figure>{" "}
                                      à récupérer à la réception
                                    </span>
                                  ) : null}
                                  {this.state.service.delayHour ? (
                                    <span>
                                      <figure>
                                        <IconTimeLimit />
                                      </figure>{" "}
                                      délai de {this.state.service.delayHour}h
                                    </span>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>

                      {this.state.orderability?.type ===
                      orderabilityType.ORDERABLE ? (
                        <Col className="bookingForm">
                          <span className="title">Réserver ma prestation</span>

                          <Form onSubmit={this.onSubmit} className="infosForm">
                            <div className="mb-3">
                              <i>
                                Vous n'avez rien à débourser maintenant. <br />
                                Si besoin,{" "}
                                {this.props.hosterType === "Gite"
                                  ? "le gîte"
                                  : "la réception"}{" "}
                                vous contactera pour plus de précision sur votre
                                commande.
                              </i>
                            </div>
                            <h5>Ma réservation</h5>

                            <Label for="quantity">
                              Quantité <span className="gwPink">*</span>
                            </Label>
                            <FormGroup className="form-group">
                              <div className="quantitySpinner">
                                <InputSpinner
                                  type={"int"}
                                  precision={1}
                                  name={"quantity"}
                                  id="quantity"
                                  max={15}
                                  min={1}
                                  step={1}
                                  value={1}
                                  onChange={(event) =>
                                    this.handleQuantityChange(event)
                                  }
                                  variant={"light"}
                                  size="sm"
                                />
                              </div>
                            </FormGroup>
                            {/* <Row> */}
                            <FormGroup className="form-group col">
                              <Label for="details">Détails</Label>
                              <Input
                                id="details"
                                name="details"
                                placeholder="Chambre, date, heure, allergies ..."
                                type="text"
                                value={this.state.details}
                                onChange={(event) => this.handleChange(event)}
                              />
                            </FormGroup>
                            {/* </Row> */}

                            <h5>Mes informations</h5>
                            <Row>
                              <FormGroup className="form-group col">
                                <Label for="lastname">
                                  Nom <span className="gwPink">*</span>
                                </Label>
                                <Input
                                  id="lastname"
                                  name="lastname"
                                  placeholder="Exemple : Dupont"
                                  type="text"
                                  required={true}
                                  value={this.state.lastname}
                                  onChange={(event) => this.handleChange(event)}
                                />
                              </FormGroup>

                              <FormGroup className="form-group col">
                                <Label for="firstname">
                                  Prénom <span className="gwPink">*</span>
                                </Label>
                                <Input
                                  id="firstname"
                                  name="firstname"
                                  placeholder="Exemple : Jean"
                                  type="text"
                                  required={true}
                                  value={this.state.firstname}
                                  onChange={(event) => this.handleChange(event)}
                                />
                              </FormGroup>
                            </Row>
                            {this.props.router.params.slug !==
                            "operaliegeparis" ? (
                              <Row>
                                <FormGroup className="form-group col">
                                  <Label for="email">E-mail</Label>
                                  <Input
                                    id="email"
                                    name="email"
                                    placeholder="jeandupont@gmail.com"
                                    type="email"
                                    value={this.state.email}
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="form-group col">
                                  <Label for="phone">
                                    N° de téléphone{" "}
                                    {this.props.router.params.slug ===
                                      "citylodge" ||
                                    this.props.router.params.slug ===
                                      "citylodgecollection" ||
                                    this.props.router.params.slug ===
                                      "thnation" ||
                                    this.props.router.params.slug ===
                                      "thfontaine" ? (
                                      <span className="gwPink">*</span>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </Label>
                                  <Input
                                    id="phone"
                                    name="phone"
                                    placeholder="0123456489"
                                    type="text"
                                    value={this.state.phone}
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    required={
                                      this.props.router.params.slug ===
                                        "citylodge" ||
                                      this.props.router.params.slug ===
                                        "citylodgecollection" ||
                                      this.props.router.params.slug ===
                                        "thfontaine" ||
                                      this.props.router.params.slug ===
                                        "thnation"
                                        ? true
                                        : false
                                    }
                                  />
                                </FormGroup>
                              </Row>
                            ) : null}
                            <Row className="BtnContainer">
                              {/* <Col> */}
                              <Button
                                className="customBtn btn-blue smooth-scroll"
                                // onClick={this.onSubmit}
                                type="submit"
                                disabled={this.state.beingProcessed}
                              >
                                {this.state.beingProcessed ? (
                                  <Spinner color="secondary" size="" />
                                ) : null}
                                <span>{this.displayButtonText()}</span>
                              </Button>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      ) : this.state.orderability?.type ===
                        orderabilityType.REDIRECTION ? (
                        <Col className="bookingNotOrderable">
                          <div className="col button-wrapper">
                            <Button
                              className="customBtn btn-blue smooth-scroll"
                              // onClick={this.onSubmit}
                              href={this.state.orderability.redirectUrl}
                              target="_blank"
                            >
                              {this.state.orderability.labelButton
                                ? this.state.orderability.labelButton
                                : "Commander"}
                            </Button>
                          </div>
                        </Col>
                      ) : this.state.orderability.labelButton ? (
                        <Col className="bookingNotOrderable">
                          {/* <span className="title">Réserver ma prestation</span> */}
                          <h5>{this.state.orderability.labelButton}</h5>
                        </Col>
                      ) : null}
                    </Row>
                  </Row>
                </div>

                {this.state.orderability?.type ===
                orderabilityType.ORDERABLE ? (
                  <BtmSheet
                    header={
                      <>
                        <Col className="detailPrice col">
                          {this.state.price.type === priceType.TEXT ? (
                            <PriceContext.Consumer>
                              {(value) => (
                                <>
                                  {value({
                                    value: this.state.price?.txtPrice,
                                    price: this.state.price,
                                  })}
                                </>
                              )}
                            </PriceContext.Consumer>
                          ) : (
                            <PriceContext.Consumer>
                              {(value) => (
                                <>
                                  {value({
                                    value: this.state.price?.value,
                                    price: this.state.price,
                                  })}
                                </>
                              )}
                            </PriceContext.Consumer>
                          )}
                        </Col>
                        <Col id="info">
                          <div className="infos">
                            <i>
                              Vous n'avez rien à <br /> débourser maintenant.
                              {/* Si besoin,{" "}
                          {this.props.hosterType === "Gite"
                            ? "le gîte"
                            : "la réception"}{" "}
                          vous contactera pour plus de précision sur votre
                          commande. */}
                            </i>
                          </div>
                        </Col>
                      </>
                    }
                    hosterType={this.props.hosterType}
                    visible={
                      this.state.orderability?.type ===
                      orderabilityType.ORDERABLE
                    }
                    customLabel={this.displayButtonText()}
                  >
                    <Col className="bookingFormMobile">
                      {/* <div className="drawer-bottom"> */}
                      <h3 className="booking-title">Réserver ma prestation</h3>

                      <Form onSubmit={this.onSubmit} className="infosForm">
                        <div className="mb-3">
                          <i>
                            Si besoin,{" "}
                            {this.props.hosterType === "Gite"
                              ? "le gîte"
                              : "la réception"}{" "}
                            vous contactera pour plus de précision sur votre
                            commande.
                          </i>
                        </div>
                        <h5>Ma réservation</h5>

                        <Label for="quantity">
                          Quantité <span className="gwPink">*</span>
                        </Label>
                        <FormGroup className="form-group quantity">
                          <div className="quantitySpinner">
                            <InputSpinner
                              type={"int"}
                              precision={1}
                              name={"quantity"}
                              id="quantity"
                              max={15}
                              min={1}
                              step={1}
                              value={1}
                              onChange={(event) =>
                                this.handleQuantityChange(event)
                              }
                              variant={"light"}
                              size="sm"
                            />
                          </div>
                          {/* <i>{this.state.service.label}</i> */}
                        </FormGroup>
                        <FormGroup className="form-group col">
                          <Label for="details">Détails</Label>
                          <Input
                            id="details"
                            name="details"
                            placeholder="Chambre, date, heure, allergies ..."
                            type="text"
                            value={this.state.details}
                            onChange={(event) => this.handleChange(event)}
                          />
                        </FormGroup>

                        <h5>Mes informations</h5>
                        <Row>
                          <FormGroup className="form-group col">
                            <Label for="lastname">
                              Nom <span className="gwPink">*</span>
                            </Label>
                            <Input
                              id="lastname"
                              name="lastname"
                              placeholder="Exemple : Dupont"
                              type="text"
                              required={true}
                              value={this.state.lastname}
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>

                          <FormGroup className="form-group col">
                            <Label for="firstname">
                              Prénom <span className="gwPink">*</span>
                            </Label>
                            <Input
                              id="firstname"
                              name="firstname"
                              placeholder="Exemple : Jean"
                              type="text"
                              required={true}
                              value={this.state.firstname}
                              onChange={(event) => this.handleChange(event)}
                            />
                          </FormGroup>
                        </Row>
                        {this.props.router.params.slug !== "operaliegeparis" ? (
                          <Row>
                            <FormGroup className="form-group col">
                              <Label for="email">E-mail</Label>
                              <Input
                                id="email"
                                name="email"
                                placeholder="jeandupont@gmail.com"
                                type="email"
                                value={this.state.email}
                                onChange={(event) => this.handleChange(event)}
                              />
                            </FormGroup>
                            <FormGroup className="form-group col">
                              <Label for="phone">
                                N° de téléphone{" "}
                                {this.props.router.params.slug ===
                                  "citylodge" ||
                                this.props.router.params.slug ===
                                  "citylodgecollection" ? (
                                  <span className="gwPink">*</span>
                                ) : (
                                  ""
                                )}{" "}
                              </Label>
                              <Input
                                id="phone"
                                name="phone"
                                placeholder="0123456489"
                                type="text"
                                value={this.state.phone}
                                onChange={(event) => this.handleChange(event)}
                                required={
                                  this.props.router.params.slug ===
                                    "citylodge" ||
                                  this.props.router.params.slug ===
                                    "citylodgecollection"
                                    ? true
                                    : false
                                }
                              />
                            </FormGroup>
                          </Row>
                        ) : null}

                        <Row className="BtnContainer">
                          <Col>
                            <Button
                              className="customBtn btn-blue smooth-scroll"
                              // onClick={this.onSubmit}
                              type="submit"
                              disabled={this.state.beingProcessed}
                            >
                              {this.state.beingProcessed ? (
                                <Spinner color="secondary" size="" />
                              ) : null}
                              <span>{this.displayButtonText()}</span>
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                      {/* </div> */}
                    </Col>
                  </BtmSheet>
                ) : this.state.orderability?.type ===
                  orderabilityType.REDIRECTION ? (
                  <div className="orderableSection">
                    <Col className="detailPrice col-4">
                      {this.state.price.type === priceType.TEXT ? (
                        <PriceContext.Consumer>
                          {(value) => (
                            <>
                              {value({
                                value: this.state.price?.txtPrice,
                                price: this.state.price,
                              })}
                            </>
                          )}
                        </PriceContext.Consumer>
                      ) : (
                        <PriceContext.Consumer>
                          {(value) => (
                            <>
                              {value({
                                value: this.state.price?.value,
                                price: this.state.price,
                              })}
                            </>
                          )}
                        </PriceContext.Consumer>
                      )}
                    </Col>
                    {this.state.orderability?.type ===
                    orderabilityType.REDIRECTION ? (
                      <Col id="info">
                        <div className="redirection-btn">
                          <Button
                            className="customBtn btn-blue smooth-scroll"
                            // onClick={this.onSubmit}
                            href={this.state.orderability.redirectUrl}
                            target="_blank"
                          >
                            {this.state.orderability.labelButton
                              ? this.state.orderability.labelButton
                              : "Commander"}
                          </Button>
                        </div>
                      </Col>
                    ) : null}
                  </div>
                ) : (
                  <div className="orderableSection">
                    <Col className="detailPrice col-4">
                      {this.state.price.type === priceType.TEXT ? (
                        <PriceContext.Consumer>
                          {(value) => (
                            <>
                              {value({
                                value: this.state.price?.txtPrice,
                                price: this.state.price,
                              })}
                            </>
                          )}
                        </PriceContext.Consumer>
                      ) : (
                        <PriceContext.Consumer>
                          {(value) => (
                            <>
                              {value({
                                value: this.state.price?.value,
                                price: this.state.price,
                              })}
                            </>
                          )}
                        </PriceContext.Consumer>
                      )}
                    </Col>
                    {this.state.orderability.labelButton &&
                    this.state.orderability?.type ===
                      orderabilityType.NOTORDERABLE ? (
                      <Col id="info">
                        <div className="infos">
                          <i>{this.state.orderability.labelButton}</i>
                        </div>
                      </Col>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      );
  }
}

export default withRouter(ServiceDetail);
