import React from "react";
import { HeaderImage } from "./header/headerImage";
import { HeaderText } from "./header/headerText";
import { CardBody } from "reactstrap";

const Header = ({ name, slug }) => {
  return (
    <CardBody>
      <HeaderImage name={name} slug={slug} />
      <HeaderText />
    </CardBody>
  );
};

export default Header;
