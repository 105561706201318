import axios from "axios";

export const BucketFolder = {
  Passports: "passports",
  Signatures: "signatures",
};

export const postAssetsLoadPrivateDocument = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/assets/upload/privatedocument",
      data
    );

    return response.data;
  } catch (error) {
    throw new Error(`Failed to post assets load private document: ${error}`);
  }
};

export async function convertBase64ToFormData(
  base64,
  customerId,
  bucketFolder
) {
  if (!base64) return "";

  const base64Data = base64.replace(/^data:image\/[a-z]+;base64,/, "");
  const byteCharacters = atob(base64Data);
  const byteArrays = [new Uint8Array(byteCharacters.length)];
  for (let offset = 0; offset < byteCharacters.length; offset += 1) {
    byteArrays[0][offset] = byteCharacters.charCodeAt(offset);
  }
  const blob = new Blob(byteArrays, { type: "image/png" });
  const file = new File([blob], `${customerId}_signature.png`, {
    type: "image/png",
  });
  const data = new FormData();
  data.append("file", file);
  data.append("folder", bucketFolder);
  return postAssetsLoadPrivateDocument(data);
}
