import React, { useCallback } from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../inputErrorMessage";

export const PersonCounter = ({
  send,
  personCount,
  setPersonCount,
  isValidPersonCountFormat,
  setIsValidPersonCountFormat,
}) => {
  const handlePersonCountChange = useCallback(
    (e) => {
      const value = Number(e.target.value);
      if (!isNaN(value) && value > 0) {
        if (!isValidPersonCountFormat) setIsValidPersonCountFormat(true);
        setPersonCount(value);
        return;
      }
      if (isValidPersonCountFormat) setIsValidPersonCountFormat(false);
      setPersonCount("");
    },
    [personCount, setPersonCount, setIsValidPersonCountFormat]
  );

  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="personCounter">
          Nombre de personne(s)<span className="gwPink">*</span>
          {send && !personCount && <InputErrorMessage />}
          {!send && !isValidPersonCountFormat && (
            <InputErrorMessage message={"Valeur invalide"} />
          )}
        </Label>
        <Input
          id="personCounter"
          value={personCount}
          onChange={handlePersonCountChange}
          required
        />
      </FormGroup>
    </Row>
  );
};
