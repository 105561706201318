import React, { useCallback } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../inputErrorMessage";
import { EMAIL_PATTERN } from "../../../../constants/checkinType";

const Infos = ({
  send,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  email,
  setEmail,
  isEmailError,
  setIsEmailError,
  phoneNumber,
  setPhoneNumber,
}) => {
  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);

      if (!EMAIL_PATTERN.test(e.target.value)) {
        setIsEmailError(true);
      } else {
        setIsEmailError(false);
      }
    },
    [email, setEmail, setIsEmailError]
  );

  const handlePhoneNumberChange = useCallback(
    (value) => {
      setPhoneNumber(formatPhoneNumberIntl(value));
    },
    [setPhoneNumber]
  );
  return (
    <>
      <Row>
        <FormGroup className="form-group col">
          <Label for="lastName">
            Nom<span className="gwPink">*</span>
            {send && !lastName && <InputErrorMessage />}
          </Label>
          <Input
            id="lastName"
            type="text"
            placeholder="Nom"
            value={lastName}
            onChange={handleLastNameChange}
            required
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="form-group col">
          <Label for="firstName">
            Prénom<span className="gwPink">*</span>
            {send && !firstName && <InputErrorMessage />}
          </Label>
          <Input
            id="firstName"
            type="text"
            placeholder="Prénom"
            value={firstName}
            onChange={handleFirstNameChange}
            required
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="form-group col">
          <Label for="email">
            E-mail<span className="gwPink">*</span>
            {send && !email && <InputErrorMessage />}
            {send && email && isEmailError && (
              <InputErrorMessage message={"Erreur de format d'email"} />
            )}
          </Label>
          <Input
            id="email"
            type="email"
            placeholder="Address email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="form-group col">
          <Label for="phoneNumber">Téléphone</Label>
          <PhoneInput
            id="phoneNumber"
            placeholder="Téléphone"
            international
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="form-control checkin-input"
            defaultCountry="FR"
            required
          />
        </FormGroup>
      </Row>
    </>
  );
};

export default Infos;
