import React, { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Col, Row, Button } from "reactstrap";
import { ReactComponent as IconClose } from "../../../assets/images/icon-closeCross.svg";

import "react-spring-bottom-sheet/dist/style.css";

export default function BtmSheet(props) {
  const [_visible, setVisible] = useState('block');
  const [close, setClose] = useState('none')
  const sheetRef = React.useRef();
  return (
    <>
      <BottomSheet
        className="BtmSheet"
        open={true}
        ref={sheetRef}
        blocking={false}
        scrollLocking={false}
        skipInitialTransition
        snapPoints={({ headerHeight, minHeight }) => [headerHeight, minHeight]}
        onSpringStart={(event) => {
          requestAnimationFrame(() => {
            if (sheetRef.current.height > 500) {
              setVisible("none");
              setClose("block")
            } else {
              setVisible("block");
              setClose('none')
            }
          });
          // if (event.type === "SNAP" && event.source === "dragging") {
          //   console.log(
          //   );
          // }
        }}
        // onSpringEnd={(event) => {
        //   if (event.type === "SNAP" && event.source === "snap-to-bottom") {
        //     console.log(
        //     );
        //   }
        //   if (event.type === "SNAP" && event.source === "snap-to-header") {
        //     console.log(
        //     );
        //   }
        // }}
        header={
          <Row className="flashInfo">
            {" "}
            {props.header}{" "}
            <Col className="buttons_animate" style={{display : props.visible ? "block" : "none"}}>
              <Button
              style={{display : _visible, visibility: props.visible ? "visible" : "hidden"}}
                className="switchBtn customBtn btn-blue smooth-scroll"
                onClick={() => {
                  sheetRef.current.snapTo(700, { source: "snap-to-header" });
                }}
              >{props.customLabel ? props.customLabel :
                <> J'informe{" "}
                {props.hosterType === "Gite" ? "le gîte" : "la réception"}{" "}
                <br /> de ma demande </>}
              </Button>
              <Button
              style={{display : close }}
                className="la closeBtn smooth-scroll"
                onClick={() => {
                  sheetRef.current.snapTo(0, { source: "snap-to-bottom" });
                }}
              >
                <IconClose />
              </Button>
            </Col>
          </Row>
        }
      >
        {props.children}
      </BottomSheet>
    </>
  );
}
