import {
  useParams,
  useSearchParams,
  useLocation
} from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();
    let searchParams = useSearchParams();
    let location = useLocation();
    return (
      <Component
        {...props}
        router={{ params, searchParams, location }}
      />
    );
  }

  return ComponentWithRouterProp;
}
