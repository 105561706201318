import React, { useCallback } from "react";
import { FormGroup, Input, Row } from "reactstrap";

export const CheckText = ({ send, text, value, setValue }) => {
  const handleCheckboxChange = useCallback(() => {
    setValue((prevValue) => !prevValue);
  }, [setValue]);

  return (
    <Row>
      <FormGroup className="form-group col check-box-flex">
        <div>
          <Input
            id="checkList"
            type="checkbox"
            checked={value}
            onChange={handleCheckboxChange}
            style={{ marginRight: "1rem" }}
          />
        </div>
        <span
          style={{ fontWeight: "350" }}
          className={`${!value && send && "check-box-text"}`}
        >
          {text}
        </span>
      </FormGroup>
    </Row>
  );
};
