import react from "react";
import { Link } from "react-router-dom";

const NotFound = (props) => {
  const slug = props.slug;
  return (
    <div className="notfound container">
      <div className="elements">
        <h1>OOPS !</h1>
        <span>Il n'y a rien à voir ici...</span><br/>
        <Link to={slug + "/"}>Retourner à l'écran d'accueil</Link>
      </div>
    </div>
  );
};

export default NotFound;
