import React from "react";
import { Label } from "reactstrap";
import { SatisfactionRatingButton } from "./ review/satisfactionRatingButton";
import { FeedbackRequestModal } from "./ review/feedbackRequestModal";

export const Review = ({
  satisfactionLevel,
  setSatisfactionLevel,
  message,
  setMessage,
}) => {
  return (
    <>
      {/* Satisfaction Rating Button */}
      <SatisfactionRatingButton
        satisfactionLevel={satisfactionLevel}
        setSatisfactionLevel={setSatisfactionLevel}
      />
      {/* Satisfaction Rating Button */}
      <FeedbackRequestModal
        message={message}
        setMessage={setMessage}
        satisfactionLevel={satisfactionLevel}
      />
    </>
  );
};
