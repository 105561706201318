import React, { useState, useEffect } from "react";

import { Col, Accordion, AccordionHeader } from "reactstrap";
import { Collapse } from "reactstrap";

const HostInfos = (hoster) => {
  const [isOpen, setIsOpen] = useState(false);
  const [host, setHost] = useState();
  const toggle = () => setIsOpen(!isOpen);

  const getHostInfos = async () => {
    setHost(hoster.hoster);
  };

  useEffect(() => {
    getHostInfos();
  });
  return (
    <div className="hostName" id="hostname">
      {host ? (
        <div>
          <figure>
            <img alt="logo" src={host.logo} />
          </figure>
          <h5> {host.name}</h5>
        </div>
      ) : null}
    </div>
  );
};

export default HostInfos;
