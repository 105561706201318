import React, { Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardColumns,
  CardText,
  CardTitle,
  CardFooter,
  Button,
  CardHeader,
  Spinner,
} from "reactstrap";

import { Link } from "react-router-dom";

import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
import { ReactComponent as IconDuration } from "../../../assets/images/icon-duration.svg";
import  DefaultImgActivities from "../../../assets/images/default-activity.jpg";
import {PriceContext} from "../../../App"

class Activities extends React.Component {
  constructor(props) {
    super();
    this.state = {
      activities: props.activities,
      city: props.hosterCity,
      hosterType: props.hosterType,
    };
  }

  product_type(product_type) {
    switch (product_type) {
      case "CityPass":
        return "City Pass";
      case "BilletEntree":
        return "Billet d'entrée";
      case "VisiteGuidee":
        return "Visite guidée";
      case "ExcursionJournee":
        return "Excursion journée";
      case "VisitePrivee":
        return "Visite privée";
      case "CircuitArretsMultiples":
        return "Circuit à arrêts multiples";
      case "ActiviteAquatique":
        return "Activité aquatique";
      case "AtelierCours":
        return "Atelier ou Cours";
      case "BilletAcompagnateur":
        return "Billet avec accompagnateur";
      case "OffreCombinee":
        return "offre combinée";

      default:
        return product_type;
    }
  }

  render() {
    return (
      <Container className="mainActivities">
        <p className="headerDescription">
          Découvrez les activités et expériences {this.state.hosterType ? "en partenariat avec votre " +
          this.state.hosterType : null }.
        </p>
        {this.state.activities ? (
          <Container className="activitiesGroup mt-4 mb-4 justify-content-center align-items-center">
            {this.state.activities.map((activity) => {
              return (
                <Fragment key={activity.id}>
                  <div id={activity.id} className="ancor"></div>
                  <CardColumns className="row row-cols-12 row-cols-lg-12">
                    <Card
                      className=" col-12 activity bg-light"
                      key={activity.id}
                    >
                      <Link to={`activite/${activity.id}/`}>
                        <Row>
                          <div className="col-5 col-lg-4 img">
                            {activity.product_type ? (
                              <div className="pills">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9.501"
                                  height="5"
                                  viewBox="0 0 9.501 5"
                                >
                                  <path
                                    id="Tracé_40"
                                    data-name="Tracé 40"
                                    d="M3.321,1.566,9.5,0V5H0C0,2.239,2.216,2.051,3.321,1.566Z"
                                  />
                                </svg>{" "}
                                <span>
                                  {this.product_type(activity.product_type)}
                                </span>
                              </div>
                            ) : null}
                            {activity.photos.length > 0 ? (
                              <figure
                                className="cardImg-top"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.REACT_APP_API_STRAPI +
                                    activity.photos[0].url
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              ></figure>
                            ) : (
                              <figure
                                className="cardImg-top"
                                style={{
                                  backgroundImage: `url(${DefaultImgActivities})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              ></figure>
                            )}
                          </div>
                          {/* Add a marketing pills : <span className="bonplan"> Bon plan</span> */}
                          <div className="col-7 col-lg-8 description">
                            <CardHeader>
                              <CardTitle tag="h5">
                                {activity.title_short}
                              </CardTitle>
                            </CardHeader>
                            <CardBody className="activityBody">
                              <CardText className="mt-2 px-2 description">
                                <div className="location">
                                  <IconSpot />
                                  <span>{activity.city}</span>
                                </div>
                                {/* <i className="distance">500m</i> */}
                                {activity.duration !== null ? (
                                  <div className="duration">
                                    <IconDuration />
                                    <span>{activity.duration}</span>
                                  </div>
                                ) : null}
                              </CardText>
                              <CardText className="mt-2 px-2 description_mobile d-sm-block d-md-none">
                                <div className="location">
                                  <IconSpot />
                                  <span>{activity.city}</span>
                                </div>
                                {/* <i className="distance">500m</i> */}
                                {activity.duration !== null ? (
                                  <div className="duration">
                                    <IconDuration />
                                    <span>{activity.duration}</span>
                                  </div>
                                ) : null}
                              </CardText>
                            </CardBody>
                            <CardFooter>
                              <Row className="AddBtn-container">
                                <Col>
                                  <Button
                                    className="AddBtn"
                                    // onClick={(e) => this.showModal(service)}
                                  >
                                    En savoir +
                                  </Button>
                                </Col>
                              </Row>
                            </CardFooter>
                          </div>
                        </Row>
                        <Row>
                          <div className="Activity">
                            <span>{activity.activity_price_mention} </span>
                               {" "} <PriceContext.Consumer>
                              {value => (
                                <>{value({value: activity.activity_price, style: "inline"})}</>
                              )}
                            </PriceContext.Consumer>
                            <span> {activity.activity_price_modality}</span>
                          </div>
                        </Row>
                      </Link>
                    </Card>
                  </CardColumns>
                </Fragment>
              );
            })}
          </Container>
        ) : (
          <div className="SpinnerLoaderWrapper">
            <Spinner color="info" size="">
              {" "}
              Loading...
            </Spinner>
          </div>
        )}
      </Container>
    );
  }
}

export default Activities;
