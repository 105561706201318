import React from "react";
import { Link } from "react-scroll";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
    this.props.categories.forEach((category) => {
      this[category._id] = React.createRef();
    });
  }

  scrollToCategory = id => {
    const defaultActive = document.getElementById("0-nav")
    defaultActive.classList.remove('defaultActive')
    if(this[id].current !== null){
      this[id].current.scrollIntoView({
        inline: "center",
      });
    }
  };
  
  render() {
    return (
      <nav
      className="menuContainer nav-pills navbar navbar-light justify-content-center"
      id="navbar-itemsMenu"
      >
        <ul className="menuCategory nav nav-pills mb-3">
          {this.props.categories.map((category, index) => {
            return (
              <li
                className="nav-item"
                key={category._id}
                ref={this[category._id]}
                style={{
                  display: "inline-block",
                }}
              >
                <Link
                  activeclassName="activeCategoryLink"
                  className={`nav-link ${category._id} ${this.state.active === index ? 'defaultActive' : ''}` }
                  id={index + '-nav'}
                  to={category._id.toString()}
                  spy={true}
                  smooth={true}
                  duration={800}
                  offset={-90}
                  onSetActive={() => this.scrollToCategory(category._id)}
                >
                  {category.categoryLabel}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}
