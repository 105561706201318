import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormGroup, Label, Row } from "reactstrap";
import { InputErrorMessage } from "../inputErrorMessage";
import fr from "date-fns/locale/fr";

const BirthdaySelector = ({ send, birthday, setBirthday }) => {
  const handleDateChange = useCallback(
    (dateByDatePicker) => {
      const currentDate = new Date();

      dateByDatePicker.setHours(currentDate.getHours());
      dateByDatePicker.setMinutes(currentDate.getMinutes());
      dateByDatePicker.setSeconds(currentDate.getSeconds());

      setBirthday(dateByDatePicker);
    },
    [setBirthday]
  );

  return (
    <Row>
      <FormGroup className="form-group col">
        <Label for="birthday">
          Date de naissance <span className="gwPink">*</span>
          {send && !birthday && <InputErrorMessage />}
        </Label>
        <DatePicker
          id="birthday"
          placeholderText="Date de naissance"
          showPopperArrow={false}
          dateFormat="dd/MM/yyyy"
          selected={birthday}
          onChange={handleDateChange}
          showYearDropdown
          yearDropdownItemNumber={100}
          scrollableYearDropdown={true}
          showMonthDropdown
          isClearable
          dropdownMode="select"
          closeOnScroll={false}
          maxDate={new Date()}
          wrapperClassName="form-control checkin-input"
          locale={fr}
          required
        />
      </FormGroup>
    </Row>
  );
};

export default BirthdaySelector;
