import React, { useCallback, useState } from "react";
import "react-toggle/style.css";
import { FormGroup, Label } from "reactstrap";

export const AccommodationPurposeSelector = ({ setAccommodationPurpose }) => {
  const [purpose, setPurpose] = useState("Travail");
  const leftToggle = "Travail";
  const rightToggle = "Loisir";

  const handlerToggle = useCallback(
    (e) => {
      e.preventDefault();
      setPurpose((preValue) => {
        if (preValue === leftToggle) return rightToggle;
        return leftToggle;
      });
      setAccommodationPurpose((preValue) => {
        if (preValue === "work") return "leisure";
        return "work";
      });
    },
    [setPurpose, setAccommodationPurpose, purpose]
  );

  return (
    <React.Fragment>
      <FormGroup className="form-group col">
        <Label for="toggleSelector">
          But de votre séjour
          <span className="gwPink">*</span>
        </Label>
        <div
          onClick={handlerToggle}
          className="form-control flex-toggle-selector"
        >
          <div
            className={`toggle-box ${
              purpose === leftToggle
                ? "toggle-box-background toggle-box-left"
                : ""
            }`}
          >
            {leftToggle}
          </div>
          <div
            className={`toggle-box ${
              purpose === rightToggle
                ? "toggle-box-background toggle-box-right"
                : ""
            }`}
          >
            {rightToggle}
          </div>
        </div>
      </FormGroup>
    </React.Fragment>
  );
};

export default AccommodationPurposeSelector;
