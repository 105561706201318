import React, { PureComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import Services from "./views/pages/services";
import ServiceDetail from "./views/pages/services/ServiceDetail";
import WelcomeModal from "./views/pages/roomdirectory/welcomeModal";
import ServiceSheet from "./views/pages/service-sheet";
// import Activities from "./views/pages/activities";
import PageLayout from "./views/pages/layout/Layout";
import NotFound from "./views/pages/NotFound";
import AroundHoster from "./views/pages/aroundhoster";
import OfferDetails from "./views/pages/aroundhoster/OfferDetails";
import ActivityDetails from "./views/pages/aroundhoster/ActivityDetails";
import RoomDirectory from "./views/pages/roomdirectory";
import Message from "./views/pages/roomdirectory/message";

import { withRouter } from "./router/withRouter";
import axios from "axios";
import priceType from "./constants/priceType";

import { ReactComponent as IconInfosFill } from "../src/assets/images/icon-infos-fill.svg";
import PageLayoutSheet from "./views/pages/layout/LayoutSheet";
import PWAPrompt from "react-ios-pwa-prompt2";
import "./lib/NumberFormat.formatToParts.polyfill";
import Checkin from "./views/pages/checkin";
// import CallUsPhone from "./views/components/callUsPhone";
import Mood from "./views/pages/mood";

export const PriceContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.getSlug = this.getSlug.bind(this);
    this.getHostInfos = this.getHostInfos.bind(this);
    this.defineDefaultActiveTab = this.defineDefaultActiveTab.bind(this);
    this.setCustom = this.setCustom.bind(this);

    this.state = {
      host: null,
      slug: null,
      offers: null,
      primaryColor: null,
      secondaryColor: null,
      backgroundColor: null,
      isDarkText: true,
      hosterId: null,
      hosterType: null,
      favicon: null,
      hosterName: null,
      hosterLat: null,
      hosterLong: null,
      hosterOrderableMsg: null,
      tabCount: 0,
      logo: null,
      currency: null,
      logosManifest: null,
    };
  }

  getSlug = () => {
    const _slug = this.props.router.location.pathname.split("/")[1];
    this.setState({ slug: _slug });
    document.querySelector("#favicon").setAttribute("href", this.state.logo);
    document.querySelector("#apple").setAttribute("href", this.state.logo);
    document
      .querySelector("#manifest")
      .setAttribute(
        "href",
        `${process.env.REACT_APP_GAMANIFEST}/manifest/${_slug}`
      );
    return _slug;
  };

  getHostInfos = async (_slug) => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/hoster", {
        params: { shortcut: _slug },
      })
      .then(async (response) => {
        this.setState({
          host: response.data,
          primaryColor: response.data.customization.primaryColor,
          secondaryColor: response.data.customization.secondaryColor,
          backgroundColor: response.data.customization.backgroundColor,
          isDarkText: response.data.customization.isDarkText,
          favicon: response.data.customization.favicon
            ? response.data.customization.favicon
            : `${process.env.REACT_APP_BASE_URL}CLEF_NOIRE.png`,
          hosterId: response.data._id,
          hosterType: response.data.hostType,
          hosterName: response.data.name,
          hosterLat: response.data.address.latitude,
          hosterLong: response.data.address.longitude,
          hosterOrderableMsg: response.data.customization.orderableMsg,
          currency: response.data.currency,
          logo: response.data.logo,
          logosManifest: response.data.logosManifest,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  displayPrice = (
    props,
    devise = this.state.currency ? this.state.currency : "EUR",
    unit = ""
  ) => {
    const value = props.value;
    const style = props.style;
    const price = props.price;

    const getTextPrice = (value) => {
      switch (value) {
        case "free":
          return "Gratuit";
        case "onDemand":
          return "Sur demande";
        case "onEstimate":
          return "Sur devis";
        case "included":
          return "Inclus";
        default:
          return "";
      }
    };

    if (value === undefined) {
      return null;
    }
    if (
      (price &&
        (price.type === priceType.PRICE ||
          price.type === priceType.TEXTPRICE)) ||
      !price
    ) {
      if (devise !== null) {
        var formatter = new Intl.NumberFormat(
          devise === "EUR" ? "fr-FR" : "en-IN",
          {
            style: "currency",
            currency: devise,
          }
        );
        var numberString = formatter
          .formatToParts(value)
          .map(({ type, value }) => {
            switch (type) {
              case "currency":
                return devise === "EUR" && style !== "inline"
                  ? `<sup className='decimal'>
              ${value}
            </sup>`
                  : value;
              case "decimal":
                return style === "inline" ? value : ``;
              case "fraction":
                return style === "inline"
                  ? value
                  : `<sup className='decimal'>
              ${value}
            </sup>`;
              case "literal":
                return " ";
              default:
                return value;
            }
          })
          .reduce((string, part) => string + part);
      }
    }
    return (
      <>
        {price ? (
          price.type === priceType.TEXTPRICE ||
          price.type === priceType.PRICE ? (
            <>
              {style === "inline" ? (
                <span className="">
                  <span dangerouslySetInnerHTML={{ __html: numberString }} />
                </span>
              ) : (
                <div className="priceWrapper">
                  <div className="price" id="price">
                    {value === 0 ? (
                      <h3>{style === "noFree" ? "" : "Gratuit"}</h3>
                    ) : (
                      <h3>
                        <div
                          className="Price_align-top"
                          dangerouslySetInnerHTML={{ __html: numberString }}
                        />
                      </h3>
                    )}
                  </div>
                  {unit !== "" ? <p className="unit">{unit}</p> : null}
                </div>
              )}
            </>
          ) : (
            <div className="priceWrapper">
              <div className="price" id="price">
                <h3>
                  <div className="Price_align-top">{getTextPrice(value)}</div>
                </h3>
              </div>
            </div>
          )
        ) : (
          <>
            {style === "inline" ? (
              <span className="">
                <span dangerouslySetInnerHTML={{ __html: numberString }} />
              </span>
            ) : (
              <div className="priceWrapper">
                <div className="price" id="price">
                  {value === 0 ? (
                    <h3>{style === "noFree" ? "" : "Gratuit"}</h3>
                  ) : (
                    <h3>
                      <div
                        className="Price_align-top"
                        dangerouslySetInnerHTML={{ __html: numberString }}
                      />
                    </h3>
                  )}
                </div>
                {unit !== "" ? <p className="unit">{unit}</p> : null}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  getTabCount = async () => {
    let initialValue = 0;
    initialValue += this.isFunctionnalityActivated(this.state.host, "service")
      ? 1
      : 0;
    initialValue += this.isFunctionnalityActivated(this.state.host, "activity")
      ? 1
      : 0;
    initialValue += this.isFunctionnalityActivated(this.state.host, "advantage")
      ? 1
      : 0;
    initialValue += this.isFunctionnalityActivated(
      this.state.host,
      "information"
    )
      ? 1
      : 0;
    console.log("initialValue", initialValue);
    const functionalities = Object.values(this.state.host.functionalities);
    function reduceTabCount() {
      return functionalities.reduce(function (sum, func) {
        return func !== true ? sum : sum + 1;
      }, initialValue);
    }
    this.setState({ tabCount: reduceTabCount() });
  };

  setCustom = async (
    primaryColor,
    secondaryColor,
    backgroundColor,
    isDarkText,
    favicon
  ) => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--primary-bg-color",
      primaryColor,
      "!important"
    );

    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor
    );
    document.documentElement.style.setProperty(
      "--text-color",
      isDarkText ? "black" : "white"
    );
    const _favicon = document.getElementById("favicon");
    _favicon.href = favicon;
    document.title = `GetWelcom | ${this.state.host.name}`;
  };

  isFunctionnalityActivated = (hoster, functionnality) => {
    console.log(hoster, "NAV");
    if (
      hoster === undefined ||
      hoster === null ||
      hoster.customization === undefined ||
      hoster.customization.navigation === undefined
    )
      return false;
    return (
      hoster.customization.navigation.navigationElements.find(
        (e) => e.label === functionnality
      ).isActivated || false
    );
  };

  defineDefaultActiveTab = () => {
    if (this.state.host?.isHosterMigrated) {
      window.location.href = this.redirectToGuestappv2();
      return null;
    }

    const hasInternalServices = this.isFunctionnalityActivated(
      this.state.host,
      "service"
    );
    const hasActivities = this.isFunctionnalityActivated(
      this.state.host,
      "activity"
    );
    const hasAdvantage = this.isFunctionnalityActivated(
      this.state.host,
      "advantage"
    );
    const hasRoomdirectory = this.isFunctionnalityActivated(
      this.state.host,
      "information"
    );

    if (hasInternalServices) {
      return <Navigate to={`services${this.props.router.location.search}`} />;
      // } else if (this.state.host?.functionalities?.guestbook) {
      //   return <Navigate to={`accueil${this.props.router.location.search}`} />;
    } else if (hasRoomdirectory) {
      return (
        <Navigate to={`roomdirectory${this.props.router.location.search}`} />
      );
    } else if (hasAdvantage) {
      return <Navigate to={`avantages${this.props.router.location.search}`} />;
    } else {
      return null;
    }
  };

  /*
   * Redirect to the new guestappv2
   */
  mappingRedirect = (path) => {
    switch (path) {
      case "mood":
        return "mood";
      case "roomdirectory/message":
        return "message";
      case "avantages":
        return "advantages";
      case "aroundhoster":
        return "aroundhoster";
      default:
        return path.replace("form", "services");
    }
  };

  redirectToGuestappv2 = () => {
    const pathWithoutSlug = this.props.router.location.pathname
      .split("/")
      .slice(2)
      .join("/");
    const slug = this.props.router.location.pathname.split("/")[1];

    const newPath = this.mappingRedirect(pathWithoutSlug);
    const redirect = new URL(
      slug + "/" + newPath + this.props.router.location.search,
      process.env.REACT_APP_GUESTAPP_V2
    );
    return redirect;
  };

  async componentDidMount() {
    const slug = this.getSlug();
    await this.getHostInfos(slug);
    localStorage.setItem("AlreadyVisited", true);
    if (this.state.host?.isHosterMigrated) {
      window.location.href = this.redirectToGuestappv2();
      return null;
    }
    this.setCustom(
      this.state.primaryColor,
      this.state.secondaryColor,
      this.state.backgroundColor,
      this.state.isDarkText,
      this.state.favicon
    );
    this.getTabCount();
  }

  render() {
    if (this.state.host?.isHosterMigrated) {
      window.location.href = this.redirectToGuestappv2();
      return null;
    }
    return (
      <PriceContext.Provider value={this.displayPrice}>
        <Routes>
          <Route path="*" element={<NotFound slug={this.state.slug} />} />

          <Route
            path=":slug/checkin"
            element={
              <Checkin
                slug={this.state.slug}
                hosterId={this.state.hosterId}
                name={this.state?.host?.name}
              />
            }
          />

          <Route
            path=":slug/mood"
            element={
              <Mood slug={this.state.slug} hosterId={this.state.hosterId} />
            }
          />

          <Route
            path="/"
            element={
              <>
                <PageLayout tabCount={this.state.tabCount} />{" "}
              </>
            }
          >
            <Route path=":slug/" element={this.defineDefaultActiveTab()} />
            <Route path=":slug/services" element={<Services />} />
            <Route
              path=":slug/services/:id"
              element={
                <ServiceDetail
                  hosterId={this.state.hosterId}
                  hosterType={this.state.hosterType}
                  hosterOrderableMsg={this.state.hosterOrderableMsg}
                />
              }
            />
            {/* <Route path=":slug/activites" element={<Activities />} /> */}
            <Route path=":slug/roomdirectory" element={<RoomDirectory />} />
            <Route
              path=":slug/roomdirectory/message"
              element={<Message hosterId={this.state.hosterId} />}
            />

            <Route path=":slug/avantages" element={<AroundHoster />} />
            <Route
              path=":slug/avantages/:id"
              element={
                <OfferDetails
                  hosterName={this.state.hosterName}
                  hosterLat={this.state.hosterLat}
                  hosterLong={this.state.hosterLong}
                />
              }
            />
            <Route
              path=":slug/avantages/activite/:id"
              element={
                <ActivityDetails
                  hosterName={this.state.hosterName}
                  hosterType={this.state.hosterType}
                  hosterLat={this.state.hosterLat}
                  hosterLong={this.state.hosterLong}
                />
              }
            />
          </Route>

          <Route
            path="/:slug/form/"
            element={
              <PageLayoutSheet tabCount={this.state.tabCount} /> //{" "}
            }
          >
            <Route
              path=":formId"
              element={
                <ServiceSheet
                  hosterId={this.state.hosterId}
                  hosterType={this.state.hosterType}
                  hosterOrderableMsg={this.state.hosterOrderableMsg}
                />
              }
            />
          </Route>
        </Routes>
        {this.state.host !== null ? (
          <WelcomeModal
            slug={this.state.host !== null ? this.state.host.slug : null}
            hoster={this.state.host !== null ? this.state.host.name : null}
            content={this.state.host?.popupMessage.message}
            showOnFirstVisit={this.state.host?.popupMessage.showOnFirstVisit}
          />
        ) : null}
        <PWAPrompt
          copyTitle={`Ajouter l'application d'accueil sur votre téléphone pour accéder aux informations utiles, même sans connexion.`}
          copyBody={`✅ Idéal pour préparer et profiter de votre séjour : informations utiles, services et bonnes adresses.`}
          copyShareButtonLabel={`Appuyez sur le bouton "Partager"`}
          copyAddHomeButtonLabel={`Appuyez ensuite sur "Sur écran d'accueil"`}
          promptOnVisit={1}
          timesToShow={2}
          delay={15000}
          copyClosePrompt="Fermer"
          permanentlyHideOnDismiss={false}
        />
        {/* {this.state.host !== null ? (
          <CallUsPhone
            slug={this.state.host !== null ? this.state.host.slug : null}
          ></CallUsPhone>
        ) : null} */}
      </PriceContext.Provider>
    );
  }
}
export default withRouter(App);
