import React from "react";
import { CheckText } from "./VerifyList/checkText";

export const VerifyList = ({
  send,
  smokingInRoom,
  setSmokingInRoom,
  billPaymentResponsibility,
  setBillPaymentResponsibility,
}) => {
  return (
    <React.Fragment>
      <CheckText
        send={send}
        text={"Je m’engage à ne pas fumer dans la chambre"}
        value={smokingInRoom}
        setValue={setSmokingInRoom}
      />
      <CheckText
        send={send}
        text={
          "Je m’engage personnellement au règlement de cette facture au cas ou elle ne serait pas réglée par le donneur d’ordre"
        }
        value={billPaymentResponsibility}
        setValue={setBillPaymentResponsibility}
      />
    </React.Fragment>
  );
};
