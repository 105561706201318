import React from "react";
import { Col, Container } from "reactstrap";
import axios from "axios";
import { withRouter } from "../../../router/withRouter";

class Alentour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      host: this.props.host,
      slug: this.props.slug,
      iFrameHeight: "51px",
    };
  }

  componentDidMount = () => {
    const script = document.createElement("script");
    script.src =
      "https://experiences.alentour.fr/traveler-widget/traveler-widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  render() {
    return (
      <Col className="mainOffers">
        <traveler-widget
          view="list"
          channel-uuid={this.state.host.alentourLink}
        ></traveler-widget>
      </Col>
    );
  }
}

export default withRouter(Alentour);
