import React, { useCallback } from "react";
import Image from "react-bootstrap/Image";
import { CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { SatisfactionEnum } from "../../../../constants/feedbackType";

export const SatisfactionRatingButton = ({
  satisfactionLevel,
  setSatisfactionLevel,
}) => {
  return (
    <>
      {/* Label for satisfaction level section */}
      <Label for="clientInfos" className="feedback-label-margin">
        <span className="feedback-label">Comment se passe votre séjour ?</span>
      </Label>

      {/* Satisfaction level icons */}
      <Row>
        <FormGroup className="form-group col">
          <CardBody>
            <Col className="feedback-flex">
              <Image
                src={"/feedback/mine-rejouie.png"}
                alt="Green Button"
                onClick={() => setSatisfactionLevel(SatisfactionEnum.SATISFIED)}
                className={
                  satisfactionLevel === SatisfactionEnum.SATISFIED
                    ? "icon-selected"
                    : ""
                }
              />
              <Image
                src={"/feedback/visage-triste.png"}
                alt="Yellow Button"
                onClick={() => setSatisfactionLevel(SatisfactionEnum.NEUTRAL)}
                className={
                  satisfactionLevel === SatisfactionEnum.NEUTRAL
                    ? "icon-selected"
                    : ""
                }
              />
              <Image
                src={"/feedback/froncer-les-sourcils.png"}
                alt="Red Button"
                onClick={() =>
                  setSatisfactionLevel(SatisfactionEnum.DISSATISFIED)
                }
                className={
                  satisfactionLevel === SatisfactionEnum.DISSATISFIED
                    ? "icon-selected"
                    : ""
                }
              />
            </Col>
          </CardBody>
        </FormGroup>
      </Row>
    </>
  );
};
