import React, { Fragment } from 'react'
import { Element } from 'react-scroll'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardColumns,
  CardText,
  CardTitle,
  CardFooter,
  Button,
  CardHeader
} from 'reactstrap'

import { Link } from 'react-router-dom'
import DefaultImgServices from '../../../assets/images/default-service.jpg'
import { PriceContext } from '../../../App'
import priceType from '../../../constants/priceType'
import { getTextPostPrice, getTextPrePrice } from '../../../lib/TextPriceMethods'
import ServiceType from '../../../constants/serviceType'
import { removeTags } from '../../../lib/removeTagsMethod'

class Items extends React.Component {
  constructor (props) {
    super()
    this.state = {
      //items: props.items,
      category: [],
      isopen: false,
      service: {},
      isConfirm: false
    }
    this.handler = this.handler.bind(this)
  }

  handler () {
    this.setState({
      isopen: false
    })
  }

  showModal = _service => {
    this.setState({ service: _service })
    this.setState({ isopen: true })
  }

  generateLink = (service) => {
    if (service !== undefined && service !== null) {
      if (service?.type === ServiceType.SINGLE)
        return `${service._id}`
      else // Case of a sheet
        return `../form/${service._id}`
    }
    return ".";
  }

  render () {
    return (
      <Container className='mainItems'>
        <Container className='itemsGroup mt-4 mb-4 justify-content-center align-items-center'>
          {this.props.items.map(item => {
            return (
              <Element
                name={item._id.toString()}
                className={item._id + ' Element'}
                key={'display' + item._id}
              >
                 <h4>{item.categoryLabel}</h4>
                <CardColumns className='row row-cols-2 row-cols-lg-4'>
                  {item.subcategories.map(subcategory => {
                    return subcategory.services.map(service => {
                      return (
                        <>
                          {' '}
                          {service.visible ? (
                            <Card
                              className=' col item bg-light'
                              key={service._id}
                            >
                              {/* <div className="box-shadow"> */}
                              <Link to={this.generateLink(service)} relative="path">
                                <figure
                                  className='cardImg-top'
                                  style={{
                                    backgroundImage: `url(${
                                      service.photo
                                        ? service.photo
                                        : DefaultImgServices
                                    })`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                  }}
                                ></figure>
                                {/* </div> */}
                                {/* Add a marketing pills : <span className="bonplan"> Bon plan</span> */}
                                <div className='detailPrice'>
                                  {service.price.type === priceType.PRICE ||
                                  service.price.type === priceType.TEXTPRICE ? (
                                    <PriceContext.Consumer>
                                      {value => (
                                        <>
                                          {value({
                                            value: service.price.value,
                                            price: service.price
                                          })}
                                        </>
                                      )}
                                    </PriceContext.Consumer>
                                  ) : (
                                    <PriceContext.Consumer>
                                      {value => (
                                        <>
                                          {value({
                                            value: service.price.txtPrice,
                                            price: service.price
                                          })}
                                        </>
                                      )}
                                    </PriceContext.Consumer>
                                  )}
                                </div>
                                <CardHeader>
                                  {service.price.type === priceType.TEXTPRICE &&
                                  (service.price.txtPrePrice !== 'none' ||
                                    service.price.txtPostPrice !== 'none') ? (
                                    <i className='asterisk-details'>
                                      <p>
                                        { getTextPrePrice(service.price.txtPrePrice) }
                                        {' '}
                                        <PriceContext.Consumer>
                                          {value => (
                                            <>
                                              {value({
                                                value: service.price.value,
                                                price: service.price,
                                                style: 'inline'
                                              })}
                                            </>
                                          )}
                                        </PriceContext.Consumer>{' '}
                                        { getTextPostPrice(service.price.txtPostPrice) }
                                      </p>
                                    </i>
                                  ) : null}
                                  <CardTitle tag='h5'>
                                    {service.label}
                                  </CardTitle>
                                </CardHeader>
                                <CardBody className='itemBody'>
                                  <CardText className='mt-2 px-2 description'>
                                    <small>{removeTags(service.description)}</small>
                                  </CardText>
                                </CardBody>
                                <CardFooter>
                                  <Row className='AddBtn-container'>
                                    {/* <Col>
                                <h3 className="price">
                                  {service.price === 0
                                    ? "Gratuit"
                                    : service.price + " €"}{" "}
                                </h3>
                              </Col> */}
                                    <Col>
                                      <Button
                                        className='AddBtn'
                                        // onClick={(e) => this.showModal(service)}
                                      >
                                        En savoir +
                                      </Button>
                                    </Col>
                                  </Row>
                                </CardFooter>
                              </Link>
                            </Card>
                          ) : null}
                        </>
                      )
                    })
                  })}
                </CardColumns>
              </Element>
            )
          })}
        </Container>
      </Container>
    )
  }
}

export default Items
