import React from "react";
import Image from "react-bootstrap/Image";
import { FormGroup, Row } from "reactstrap";

export const FeedbackHeader = ({ slug }) => {
  return (
    <Row>
      <FormGroup className="form-group col">
        <div className="subpic">
          <Image
            alt="hotel-img"
            src={`/${slug}.png`}
            fluid
            className="checkin-header checkin-header-img"
          />
          <div className="imtext-background"></div>
        </div>
      </FormGroup>
    </Row>
  );
};
