import React from "react";
import Offers from "../../components/offers/offers";
import Activities from "../../components/activities/activities";

import {
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { withRouter } from "../../../router/withRouter";

import axios from "axios";
import classnames from "classnames";
import Alentour from "./Alentour";

class AroundHoster extends React.Component {
  constructor(props) {
    super(props);
    this.getHostItems = this.getHostItems.bind(this);
    this.state = {
      host: [],
      slug: this.props.router.params.slug,
      activeTab: "1",
      offers: [],
      activities: [],
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getHostItems = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/partner", {
        params: { hosterId: this.state.host?._id },
      })
      .then((response) => {
        this.setState({ offers: response.data.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  getHostActivities = async () => {
    return axios
      .get(
        process.env.REACT_APP_API_STRAPI +
          `/activities?_where[hosters.hosterId_in]=${this.state.host?._id}`
      )
      .then((response) => this.setState({ activities: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  getHostInfos = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/hoster", {
        params: { shortcut: this.state.slug },
      })
      .then((response) => this.setState({ host: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  async componentDidMount() {
    await this.getHostInfos();
    this.getHostItems();
    if (this.state.host?.alentourLink?.length === 0) {
      await this.getHostActivities();
    }
    if (this.state.activities.length === 0 && this.state.host?.alentourLink?.length === 0 ) {
      this.setState({
        activeTab: "2",
      });
    }
    if (this.props.router.location.hash === "#activites") {
      this.setState({
        activeTab: "1",
      });
    }
    if (this.props.router.location.hash === "#offres") {
      this.setState({
        activeTab: "2",
      });
    }
    // TEMP : Display first the offer tab if the hoster is LieuDit
    if (
      this.props.router.location.pathname === "/lieudit-nantes/avantages" &&
      this.state.slug === "lieudit-nantes"
    ) {
      this.setState({
        activeTab: "2",
      });
    }
    // -- END --
  }

  render() {
    return this.state.activities.length === 0 &&
      this.state.offers.length === 0 &&
      this.state.host?.alentourLink?.length === 0 ? (
      <div className="SpinnerLoaderWrapper">
        <Spinner color="info" size="">
          {" "}
          Loading...
        </Spinner>
      </div>
    ) : (
      <div className="itemComponent">
        <Nav
          className="menuContainer nav-pills navbar navbar-light justify-content-center"
          id="navbar-itemsMenu"
          tabs
        >
          <ul className="menuCategory nav nav-pills mb-3">
          {(this.state.host?.alentourLink?.length > 0 || this.state.activities.length !== 0)  ? (
              <NavItem className="nav-item">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={(e) => {
                    this.toggle("1");
                  }}
                  href="#activites"
                >
                  Activités et expériences
                </NavLink>
              </NavItem>
            ) : null}
            {this.state.offers.length !== 0 ? (
              <NavItem className="nav-item">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={(e) => {
                    this.toggle("2");
                  }}
                  href="#offres"
                >
                  Les bonnes adresses de votre hôte
                </NavLink>
              </NavItem>
            ) : null}
          </ul>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          {(this.state.host?.alentourLink?.length > 0 || this.state.activities.length !== 0) ? (
            <TabPane tabId="1">
              {this.state.host?.alentourLink?.length > 0 ? (
                <Alentour slug={this.state.slug} host={this.state.host}/>
              ) : (
                <Activities
                  activities={this.state.activities}
                  hosterId={this.state.host._id}
                  hosterType={this.state.host.hostType}
                  hosterCity={this.state.host.city}
                />
              )}
            </TabPane>
          ) : null}

          {this.state.offers.length !== 0 ? (
            <TabPane tabId="2">
              <Offers
                offers={this.state.offers}
                hosterId={this.state.host._id}
                hosterType={this.state.host.hostType}
                hosterCity={this.state.host.city}
              />
            </TabPane>
          ) : null}
        </TabContent>
      </div>
    );
  }
}

export default withRouter(AroundHoster);
