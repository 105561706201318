import React from 'react'
import Items from '../../components/items'
import Menu from '../../components/menu'

import { Spinner } from 'reactstrap'
import { withRouter } from '../../../router/withRouter'

import axios from 'axios'
class Services extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      host: null,
      items: null,
      slug: this.props.router.params.slug
    }
  }

  getHostItems = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + '/product', {
        params: { hosterId: this.state.host._id }
      })
      .then(response => this.setState({ items: response.data.data }))
      .catch(error => {
        console.log(error.response)
      })
  }

  getHostInfos = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + '/hoster', {
        params: { shortcut: this.state.slug }
      })
      .then(response => this.setState({ host: response.data }))
      .catch(error => {
        console.log(error.response)
      })
  }

  async componentDidMount () {
    await this.getHostInfos()
    await this.getHostItems()
  }

  render () {
    return (
      <div className='itemComponent'>
        {this.state.host ? (
          <div className='topName'>{this.state.host.name}</div>
        ) : null}
        {this.state.items !== null ? (
          <Menu categories={this.state.items} />
        ) : (
          <div className='SpinnerLoaderWrapper'>
            <Spinner color='info' size=''>
              {' '}
              Loading...
            </Spinner>
          </div>
        )}
        {this.state.items !== null ? (
          <Items
            items={this.state.items}
            hosterId={this.state.host._id}
            hosterType={this.state.host.hostType}
          />
        ) : (
          <div className='SpinnerLoaderWrapper'>
            <Spinner color='info' size=''>
              {' '}
              Loading...
            </Spinner>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Services)
