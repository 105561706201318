import React from 'react'
import { useState } from 'react'
import { Form, FormGroup, Input, Label, Col, Row, Button } from 'reactstrap'

import axios from 'axios'

const Message = props => {
  const [customerName, setCustomerName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [roomNumber, setRoomNumber] = useState('')
  const [message, setMessage] = useState('')
  const [isConfirm, setIsConfirm] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()
    const messageData = {
      hoster: props.hosterId,
      customerName: customerName,
      email: email,
      phone: phone,
      roomNumber: roomNumber,
      message: message
    }
    axios.post(process.env.REACT_APP_API_URL + `/message`, messageData).then(
      response => {
        setIsConfirm(true)
      },
      error => {
        console.log('ERR :', error)
      }
    )
  }

  const goBack = () => {
    window.history.back()
  }

  return (
    <div className="mainMessageContainer">
      <button className='back' onClick={goBack}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-chevron-left'
          viewBox='0 0 16 16'
        >
          <path
            fillRule='evenodd'
            d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
          />
        </svg>
        <span>Infos séjour</span>
      </button>
      <div className='containerMessage'>
        {isConfirm ? (
          <div className='orderAlert'>
            <div className='success-box'>
              <div className='success-checkmark'>
                <div className='check-icon'>
                  <span className='icon-line line-tip'></span>
                  <span className='icon-line line-long'></span>
                  <div className='icon-circle'></div>
                  <div className='icon-fix'></div>
                </div>
              </div>
              <p>Merci pour votre message !</p>
              <p>
                Votre message a bien été transmis, nous allons prendre en compte
                votre remarque.
              </p>
              <Button className='customBtn btn-blue' href='../roomdirectory'>
                Ok
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Col className='MessageFormMobile'>
              {/* <h3>Envoyer un message</h3> */}
              <Form inline className='infosForm'>
                <p>
                  Pour envoyer un message à votre hôte, veuillez préciser vos
                  informations de contact afin qu'il puisse vous répondre.
                </p>
                <h5>Mes informations</h5>
                <Row>
                  <FormGroup className='form-group col'>
                    <Label for='customerName'>
                      Nom, prénom <span className='gwPink'>*</span>
                    </Label>
                    <Input
                      id='customerName'
                      name='customerName'
                      placeholder='Dupont Jean'
                      type='text'
                      required
                      value={customerName}
                      onChange={event => setCustomerName(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='form-group col'>
                    <Label for='room'>N° de chambre  <i>(Si vous êtes déjà à l'hôtel)</i></Label>
                    <Input
                      id='roomNumber'
                      name='roomNumber'
                      placeholder='110'
                      type='text'
                      value={roomNumber}
                      onChange={event => setRoomNumber(event.target.value)}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className='form-group col'>
                    <Label for='email'>
                      E-mail <span className='gwPink'>*</span>
                    </Label>
                    <Input
                      required
                      id='email'
                      name='email'
                      placeholder='jeandupont@gmail.com'
                      type='email'
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='form-group col'>
                    <Label for='room'>N° de téléphone</Label>
                    <Input
                      id='phone'
                      name='phone'
                      placeholder='0123456489'
                      type='text'
                      value={phone}
                      onChange={event => setPhone(event.target.value)}
                    />
                  </FormGroup>
                </Row>
                <h5>Mon message</h5>

                <FormGroup className='form-group col'>
                  <Label for='room'>
                    Une remarque, une question ?{' '}
                    <span className='gwPink'>*</span>
                  </Label>
                  <Input
                    rows={5}
                    required
                    id='message'
                    name='message'
                    placeholder='Ecrivez votre message ...'
                    type='textarea'
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                  />
                </FormGroup>

                <div className='BtnContainer'>
                  {/* <Col> */}
                  <Button
                    //   type='submit'
                    className='customBtn btn-blue smooth-scroll'
                    onClick={onSubmit}
                  >
                    Envoyer mon message
                  </Button>
                  {/* </Col> */}
                </div>
              </Form>
            </Col>
          </>
        )}
      </div>
    </div>
  )
}

export default Message
