import React from "react";
import { Button } from "reactstrap";

export const ConfirmModal = ({ slug, feedbackType }) => {
  const baseUrl = new URL(window.location.href).origin;

  return (
    <div className="orderAlert checkin confirm-modal">
      <div className="success-box">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <p>{`Merci pour votre ${feedbackType} !`}</p>
        <p>
          Votre message a bien été transmis, nous allons prendre en compte votre
          remarque.
        </p>
        <Button
          className="customBtn checkin-button--color"
          href={`${baseUrl}/${slug}`}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
