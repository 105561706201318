import React from "react";
import DateSelector from "./StayInfoComponent/dateSelector";
import { PersonCounter } from "./StayInfoComponent/personCounter";
import { Row } from "reactstrap";
import FirstVisitSelector from "./StayInfoComponent/firtVisitSelector";
import { AccommodationPurposeSelector } from "./StayInfoComponent/accommodationPurposeSelector";
import { EstimatedArrivalTime } from "./StayInfoComponent/estimatedArrivalTime";

export const StayInfoComponent = ({
  send,
  arrivalDate,
  setArrivalDate,
  departureDate,
  setDepartureDate,
  personCount,
  setPersonCount,
  isValidPersonCountFormat,
  setIsValidPersonCountFormat,
  setAccommodationPurpose,
  isFirstVisit,
  setIsFirstVisit,
  estimatedArrivalTime,
  setEstimatedArrivalTime,
}) => {
  return (
    <>
      <DateSelector
        send={send}
        arrivalDate={arrivalDate}
        setArrivalDate={setArrivalDate}
        departureDate={departureDate}
        setDepartureDate={setDepartureDate}
      />

      <EstimatedArrivalTime
        estimatedArrivalTime={estimatedArrivalTime}
        setEstimatedArrivalTime={setEstimatedArrivalTime}
      />

      <PersonCounter
        send={send}
        personCount={personCount}
        setPersonCount={setPersonCount}
        isValidPersonCountFormat={isValidPersonCountFormat}
        setIsValidPersonCountFormat={setIsValidPersonCountFormat}
      />
      <Row>
        <FirstVisitSelector
          isFisrtVisit={isFirstVisit}
          setIsFirstVisit={setIsFirstVisit}
        />
        <AccommodationPurposeSelector
          setAccommodationPurpose={setAccommodationPurpose}
        />
      </Row>
    </>
  );
};
