import React from "react";
import { CardText } from "reactstrap";
export const HeaderText = () => {
  return (
    <CardText>
      Pour préparer sereinement votre séjour et gagner du temps à votre arrivée,
      merci de compléter ce formulaire
    </CardText>
  );
};
