import React from "react";
import Image from "react-bootstrap/Image";
import { FormGroup, Row } from "reactstrap";

export const HeaderImage = ({ name, slug }) => {
  return (
    <Row>
      <FormGroup className="form-group col">
        <div className="subpic">
          <Image
            alt="hotel-img"
            src={`/${slug}.png`}
            fluid
            className="checkin-header checkin-header-img"
          />
          <div className="imtext-background"></div>
          <span className="imtext">FORMULAIRE D'ARRIVÉE</span>
          {name ? (
            <span className="imtext-hotel">{name.toUpperCase()}</span>
          ) : null}
        </div>
      </FormGroup>
    </Row>
  );
};
