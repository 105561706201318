import React from "react";
import Infos from "./IdentityComponent/infos";
import CountrySelector from "./IdentityComponent/countrySelector";
import BirthdaySelector from "./IdentityComponent/birthdaySelector";
import { PlaceOfBirth } from "./IdentityComponent/placeOfBirth";
import Passport from "./IdentityComponent/passport";
import { IdentityVerificationMessage } from "./IdentityComponent/identityVerificationMessage";

export const IdentityComponent = ({
  send,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  email,
  setEmail,
  setIsEmailError,
  isEmailError,
  phoneNumber,
  setPhoneNumber,
  nation,
  setNation,
  birthday,
  setBirthday,
  placeOfBirth,
  handlePlaceOfBirthChange,
  setPassport,
}) => {
  return (
    <React.Fragment>
      <Infos
        send={send}
        firstName={firstName}
        handleFirstNameChange={handleFirstNameChange}
        lastName={lastName}
        handleLastNameChange={handleLastNameChange}
        email={email}
        setEmail={setEmail}
        isEmailError={isEmailError}
        setIsEmailError={setIsEmailError}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <PlaceOfBirth
        send={send}
        placeOfBirth={placeOfBirth}
        handlePlaceOfBirthChange={handlePlaceOfBirthChange}
      />
      <BirthdaySelector
        send={send}
        birthday={birthday}
        setBirthday={setBirthday}
      />
      <CountrySelector send={send} nation={nation} setNation={setNation} />
      {/* <Passport setPassport={setPassport} /> */}
      <IdentityVerificationMessage />
    </React.Fragment>
  );
};
