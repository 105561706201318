import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconMessage } from "../../../assets/images/pages/roomdirectory/icon-message.svg";
import { ReactComponent as IconPhone } from "../../../assets/images/pages/roomdirectory/icon-telephone.svg";
import { ReactComponent as IconWhatsApp } from "../../../assets/images/pages/roomdirectory/icon-whatsapp.svg";

import Categories from "../../components/roomdirectory/Categories";
import { withRouter } from "../../../router/withRouter";
import axios from "axios";
import CallUsPhone from "../../components/callUsPhone";

class RoomDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host: null,
      roomdirectory: null,
      slug: this.props.router.params.slug,
      //   toggle: null,
    };
    // this.toggle = this.toggle.bind(this);
    this.getHostRoomDirectory = this.getHostRoomDirectory.bind(this);
  }

  getHostRoomDirectory = async () => {
    if (!this.state?.host?._id) return;

    return axios
      .get(process.env.REACT_APP_API_URL + "/roomdirectory", {
        params: { hosterId: this.state.host._id },
      })
      .then((response) => {
        this.setState({ roomdirectory: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  getHostInfos = async () => {
    if (!this.state?.slug) return;

    return axios
      .get(process.env.REACT_APP_API_URL + "/hoster", {
        params: { shortcut: this.state.slug },
      })
      .then((response) => this.setState({ host: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  async componentDidMount() {
    await this.getHostInfos();
    this.getHostRoomDirectory();
  }

  render() {
    return (
      <>
        <div className="bg-imagecl"></div>
        <div className="main__roomdirectory container">
          {this.state.host ? (
            <div className="host__infos">
              <figure>
                <img alt="logo" src={this.state.host.logo} />
              </figure>
              <h5> {this.state.host.name}</h5>
            </div>
          ) : null}
          {this.state.host?.functionalities?.messages ? (
            <div className="messageContent">
              {this.state.slug === "radissonmarnelavallee" ? (
                <a
                  className="message"
                  href="https://www.messagemyhotel.com/app/5d1e0b421eb3111df748626f/100415"
                  target="_blank"
                >
                  <span className="messageContent__label">
                    Envoyer un message à la réception
                  </span>
                  <IconMessage />
                </a>
              ) : (
                <Link className="message" value="" to="message">
                  {this.state.slug === "phosphoris" ? (
                    <span className="messageContent__label">
                      Envoyez un message à Nolwenn
                    </span>
                  ) : (
                    <span className="messageContent__label">
                      Envoyer un message à la réception
                    </span>
                  )}
                  <IconMessage />
                </Link>
              )}
            </div>
          ) : null}
          {/* check which button will be displayed  */}
          {this.state.host?.functionalities?.calls ? (
            <div className="messageContent">
              <a
                className="message"
                href={`tel:${this.state.host.genericPhone.replaceAll(" ", "")}`}
                target="_blank"
              >
                <span>Appeler la réception</span>
                <IconPhone />
              </a>
            </div>
          ) : null}
          {this.state.host !== null ? (
            <CallUsPhone
              slug={this.state.host !== null ? this.state.host.slug : null}
            ></CallUsPhone>
          ) : null}
          <Categories
            slug={this.state.slug}
            host={this.state.host}
            roomdirectory={this.state.roomdirectory}
          />
        </div>
      </>
    );
  }
}

export default withRouter(RoomDirectory);
