import React, { useState, useEffect } from 'react'
import { FormFeedback, FormGroup } from 'reactstrap'
import ItemInput from './itemInput'

const OrderForm = props => {
  const initValidationOrderForm = (currentSection) => {
    return currentSection.items.map((elt) => {
      return({checked: elt?.selected ? true : false})
    })
  }

  const [indexSection, setIndexSection] = useState(props.indexSection)
  const [section, setService] = useState(props.section)
  const [label, setLabel] = useState(props.section.label)
  const [multiple, setMultiple] = useState(props.section.multiple)
  const [required, setrequired] = useState(props.section.required)
  const [validationOrderForm, setValidationOrderForm] = useState(initValidationOrderForm(props.comm[props.currentCommande].sections[props.indexSection]))
  const [errorMsg, setErrorMsg] = useState(
    required || validationOrderForm.length > 0
  )

  const isValid = () => {
    const max = multiple ? section.limit : 1
    const min = required ? 1 : 0

    const count = validationOrderForm.filter(x => x.checked === true).length
    if (count >= min && count <= max) {
      return true
    }

    return false
  }

  const isClicked = async (event, index) => {
    if (event.target.checked) {
      if (!multiple && validationOrderForm.filter(x => x.checked === true).length === 1) {
    
        const newValidationOrderForm = validationOrderForm.map((elt, idx) => {
          if (idx === index) 
            return ({checked: event.target.checked})
          else
            return ({checked: false})
        })
        setValidationOrderForm(newValidationOrderForm)
      } else {
        const newValidationOrderForm = validationOrderForm.map((elt, idx) => {
          if (idx === index) 
            return ({checked: event.target.checked})
          else
            return elt  
        })
        setValidationOrderForm(newValidationOrderForm)
      }
    } else {
      const newValidationOrderForm = validationOrderForm.map((elt, idx) => {
        if (idx === index) 
          return ({checked: false})
        else
          return elt
      })
      setValidationOrderForm(newValidationOrderForm)
    }
  }

 useEffect(() => {
  props.validations[indexSection] = isValid()
 }, [validationOrderForm])

  return (
    <>
      <FormGroup>
        <div className='sections_header-infos'>
          <h5>{label}</h5>
          {/* <br /> */}
          {section.description ? (
            <small>
              <i>{section.description}</i>
            </small>
          ) : null}
        </div>
        {!isValid() ? (
          multiple ? (
            <div className='alert-danger' id='Alert-errorMsg' role='alert'>
              Veuillez sélectionner entre 1 et {section.limit} choix{' '}
            </div>
          ) : (
            <div className='alert-danger' id='Alert-errorMsg' role='alert'>
              Veuillez sélectionner un choix{' '}
            </div>
          )
        ) : null}
        <div>
          {section.items.map((item, index) => {
            return (
              <ItemInput
                step={props.step}
                comm={props.comm}
                currentCommande={props.currentCommande}
                indexSection={props.indexSection}
                indexItem={index}
                setCommand={props.setCommand}
                item={item}
                multiple={multiple}
                required={required}
                sectionLabel={section.label}
                limit={section.limit}
                key={`Input-${section.label}-${props.step}-${index}`}
                isClicked={isClicked}
                selected={validationOrderForm[index]?.checked}
              />
            )
          })}
        </div>
      </FormGroup>
    </>
  )
}

export default OrderForm
