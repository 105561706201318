import React from "react";
import { NavLink } from "react-router-dom";

import HostInfos from "./HostInfos";

import { Nav, NavItem } from "reactstrap";
import { withRouter } from "../../../router/withRouter";

import axios from "axios";

//icons
import { ReactComponent as IconHome } from "../../../assets/images/icon-home.svg";
import { ReactComponent as IconServices } from "../../../assets/images/icon-services.svg";
import { ReactComponent as IconActivities } from "../../../assets/images/icon-activities.svg";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      host: null,
      items: null,
      slug: this.props.router.params.slug,
      guestbook: null,
      roomdirectory: null,
    };
  }

  getHostInfos = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/hoster", {
        params: { shortcut: this.state.slug },
      })
      .then((response) => this.setState({ host: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  getHostPdf = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/guestbook", {
        params: { hosterId: this.state.host._id },
      })
      .then((response) => this.setState({ guestbook: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  getHostRoomDirectory = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/roomdirectory", {
        params: { hosterId: this.state.host._id },
      })
      .then((response) => this.setState({ roomdirectory: response.data }))
      .catch((error) => {
        console.log(error.response);
      });
  };

  async componentDidMount() {
    await this.getHostInfos();
    this.getHostPdf();
    this.getHostRoomDirectory();
    // this.getTabCount();
  }

  isFunctionnalityActivated = (hoster, functionnality) => {
    if (
      hoster === undefined ||
      hoster === null ||
      hoster.customization === undefined ||
      hoster.customization.navigation === undefined
    )
      return false;
    return (
      hoster.customization.navigation.navigationElements.find(
        (e) => e.label === functionnality
      ).isActivated || false
    );
  };

  render() {
    const hasInternalServices = this.isFunctionnalityActivated(
      this.state.host,
      "service"
    );
    const hasActivities = this.isFunctionnalityActivated(
      this.state.host,
      "activity"
    );
    const hasAdvantage = this.isFunctionnalityActivated(
      this.state.host,
      "advantage"
    );
    const hasRoomdirectory = this.isFunctionnalityActivated(
      this.state.host,
      "information"
    );

    return (
      <>
        <div className="MenuLeft">
          {this.state.host !== null ? (
            <HostInfos hoster={this.state.host} />
          ) : null}
          <Nav
            tabs
            className="nav flex-column nav-pills justify-content-center"
            aria-orientation="vertical"
          >
            {hasRoomdirectory && this.state.roomdirectory?.data.length !== 0
              ? this.state.roomdirectory?.data.map((data, index) => {
                  return (
                    <NavItem key={`NavItem ${index}`}>
                      <NavLink to={`/${this.state.slug}/roomdirectory`}>
                        <IconHome />
                        Infos séjour
                      </NavLink>
                    </NavItem>
                  );
                })
              : null}
            {/* {this.state.guestbook &&
                this.state.guestbook.data.length !== 0 &&
                this.state.host?.functionalities?.guestbook
                  ? this.state.guestbook.data.map((data, index) => {
                      return (
                        <NavItem key={`NavItem ${index}`}>
                          <NavLink to={`/${this.state.slug}/accueil`}>
                            <IconHome />
                            Accueil et informations
                          </NavLink>
                        </NavItem>
                      );
                    })
                  : null} */}
            {hasInternalServices ? (
              <NavItem>
                <NavLink to={`/${this.state.slug}/services`}>
                  <IconServices />
                  Nos services
                </NavLink>
              </NavItem>
            ) : null}
            {/* {hasActivities ? (
              <NavItem>
                <NavLink
                  to={`/${this.state.slug}/activites`}

                  // onClick={(e) => {
                  //   // document.getElementById("myIframe").src =
                  //   //   "https://getwelcom.com/activities";
                  // }}
                >
                  <IconActivities />
                  Activités et expériences
                </NavLink>
              </NavItem>
            ) : null} */}
            {hasAdvantage ? (
              <NavItem>
                <NavLink to={`/${this.state.slug}/avantages`}>
                  <IconActivities />
                  Autour de l'hébergement
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </div>
        <div className="MenuBottom">
          <Nav
            tabs
            className="nav flex-row nav-pills justify-content-center"
            aria-orientation="horizontal"
          >
            {hasRoomdirectory && this.state.roomdirectory?.data.length !== 0
              ? this.state.roomdirectory?.data.map((data, index) => {
                  return (
                    <NavItem key={`NavItem ${index}`}>
                      <NavLink to={`/${this.state.slug}/roomdirectory`}>
                        <IconHome />
                        Infos séjour
                      </NavLink>
                    </NavItem>
                  );
                })
              : null}
            {/* {this.state.guestbook &&
            this.state.guestbook.data.length !== 0 &&
            this.state.host?.functionalities?.guestbook
              ? this.state.guestbook.data.map((data, index) => {
                  return (
                    <NavItem key={`NavItem ${index}`}>
                      <NavLink to={`/${this.state.slug}/accueil`}>
                        <IconHome />
                        Accueil et informations
                      </NavLink>
                    </NavItem>
                  );
                })
              : null} */}
            {hasInternalServices ? (
              <NavItem>
                <NavLink to={`/${this.state.slug}/services`}>
                  {" "}
                  <IconServices />
                  Nos services
                </NavLink>
              </NavItem>
            ) : null}
            {/* {hasActivities ? (
              <NavItem>
                <NavLink
                  to={`/${this.state.slug}/activites`}

                  // onClick={(e) => {
                  //   // document.getElementById("myIframe").src =
                  //   //   "https://getwelcom.com/activities";
                  // }}
                >
                  <IconActivities />
                  Activités et expériences
                </NavLink>
              </NavItem>
            ) : null} */}
            {hasAdvantage ? (
              <NavItem>
                <NavLink to={`/${this.state.slug}/avantages`}>
                  <IconActivities />
                  Autour de l'hébergement
                </NavLink>
              </NavItem>
            ) : null}
            {/* <NavItem>
                <NavLink

                >
                  <IconMore />
                  Plus
                </NavLink>
              </NavItem> */}
          </Nav>
        </div>
      </>
    );
  }
}

export default withRouter(Sidebar);
