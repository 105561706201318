import React from "react";
import {
  Col,
  Row,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";
import axios from "axios";
import { withRouter } from "../../../router/withRouter";
import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
import { ReactComponent as IconTel } from "../../../assets/images/icon-telephone.svg";
import { ReactComponent as IconEmail } from "../../../assets/images/icon-email.svg";
import { ReactComponent as IconPrev } from "../../../assets/images/icon-previous.svg";
import { ReactComponent as IconOpen } from "../../../assets/images/icon-open.svg";
import Map from "../../components/googlemap/googlemap";
import defaultImage from "../../../assets/images/default-activity.jpg";

import * as Leisure from "../../../assets/images/pages/partners/leisure.jpg";
import * as Sport from "../../../assets/images/pages/partners/sport.jpg";
import * as Gastronomy from "../../../assets/images/pages/partners/gastronomy.jpg";
import * as Escape from "../../../assets/images/pages/partners/escape.jpg";
import * as Shop from "../../../assets/images/pages/partners/shop.jpg";
import * as Experience from "../../../assets/images/pages/partners/experience.jpg";
import * as Discovery from "../../../assets/images/pages/partners/discovery.jpg";
import * as Eat from "../../../assets/images/pages/partners/eat.jpg";
import * as Haveadrink from "../../../assets/images/pages/partners/haveadrink.jpg";
import * as Nightlife from "../../../assets/images/pages/partners/nightlife.jpg";
import * as Child from "../../../assets/images/pages/partners/child.jpg";
import * as Activities from "../../../assets/images/pages/partners/activities.jpg";

class OfferDetails extends React.Component {
  constructor(props) {
    super(props);

    this.getHostOffers = this.getHostOffers.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.state = {
      isConfirm: false,
      offer_id: this.props.router.params.id,
      activeIndex: 0,
      offers: {},
      partner: {},
      pdf: {},
      items: null,
      offer_link: "",
      hoster: {
        hosterName: props.hosterName,
        hosterLat: props.hosterLat,
        hosterLong: props.hosterLong,
      },
      activity: {
        activityName: null,
        activityLat: null,
        activityLong: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hosterLat !== this.state.hoster.hosterLat) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterLat: nextProps.hosterLat,
        },
      }));
    }
    if (nextProps.hosterLong !== this.state.hoster.hosterLong) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterLong: nextProps.hosterLong,
        },
      }));
    }
    if (nextProps.hosterName !== this.state.hoster.hosterName) {
      this.setState((prevState) => ({
        hoster: {
          ...prevState.hoster,
          hosterName: nextProps.hosterName,
        },
      }));
    }
  }

  getHostOffers = async () => {
    return axios
      .get(process.env.REACT_APP_API_URL + "/partner/" + this.state.offer_id)
      .then((response) => {
        this.setState((prevState) => ({
          offers: response.data,
          items: response.data.images,
          offer_link: response.data.url,
          activity: {
            ...prevState.activity,
            activityName: response.data.label,
            activityLat: response.data.address.latitude,
            activityLong: response.data.address.longitude,
          },
        }));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  async componentDidMount() {
    await this.getHostOffers();
  }

  getOpeningDays(daysObj) {
    if (daysObj) {
      const days = Object.keys(daysObj);
      const values = Object.values(daysObj);
      return days.map((key, index) => {
        var day = "";
        switch (key) {
          case "monday":
            day = "Lundi";
            break;
          case "tuesday":
            day = "Mardi";
            break;
          case "wednesday":
            day = "Mercredi";
            break;
          case "thursday":
            day = "Jeudi";
            break;
          case "friday":
            day = "Vendredi";
            break;
          case "saturday":
            day = "Samedi";
            break;
          case "sunday":
            day = "Dimanche";
            break;
          default:
            day = "";
            break;
        }
        return values[index].isOpen ? (
          <li>
            <strong>{day} </strong> {values[index].info}
          </li>
        ) : (
          <li>
            <strong>{day} </strong> Fermé
          </li>
        );
      });
    }
  }

  goBack() {
    window.history.back();
  }

  getType = (type) => {
    switch (type) {
      case "gastronomy":
        return "Gastronomie";
      case "leisure":
        return "Loisir";
      case "escape":
        return "Evasion";
      case "discovery":
        return "Découverte";
      case "experience":
        return "Expérience";
      case "shop":
        return "Commerce";
      case "sport":
        return "Sport";
      case "eat":
        return "Manger";
      case "haveadrink":
        return "Boire un verre";
      case "nightlife":
        return "Vie nocturne";
      case "child":
        return "Enfant";
      case "activities":
        return "Activités";
      default:
        return "";
    }
  };

  getDefaultImage = (type) => {
    switch (type) {
      case "gastronomy":
        return Gastronomy.default;
      case "sport":
        return Sport.default;
      case "escape":
        return Escape.default;
      case "shop":
        return Shop.default;
      case "leisure":
        return Leisure.default;
      case "discovery":
        return Discovery.default;
      case "experience":
        return Experience.default;
      case "eat":
        return Eat.default;
      case "haveadrink":
        return Haveadrink.default;
      case "nightlife":
        return Nightlife.default;
      case "child":
        return Child.default;
      case "activities":
        return Activities.default;
      case "":
        return Leisure.default;
      default:
        return Leisure.default;
    }
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        {this.state.isConfirm ? (
          <div className="success-box">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <p>Merci pour votre réservation !</p>
            <p>
              Nous vous contacterons au plus vite pour personnaliser votre
              demande.
            </p>
            <Button className="customBtn btn-blue" onClick={this.onClose}>
              Ok
            </Button>
          </div>
        ) : (
          <div className="OfferSection">
            <button className="back" onClick={this.goBack}>
              <IconPrev />
              Nos bonnes adresses
            </button>
            <div className="bbody container">
              <Row className="m-0">
                {/* <div className="box-shadow"> */}
                {this.state.items && this.state.items.length > 0 ? (
                  <Carousel
                    dark
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                  >
                    <CarouselIndicators
                      items={this.state.items}
                      activeIndex={activeIndex}
                      onClickHandler={this.goToIndex}
                    />
                    {this.state.items.map((item) => {
                      return (
                        <CarouselItem
                          onExiting={this.onExiting}
                          onExited={this.onExited}
                          key={item}
                        >
                          <figure
                            className="cardImg-top"
                            style={{
                              backgroundImage: `url(${item})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></figure>
                        </CarouselItem>
                      );
                    })}
                    {this.state.items.length > 0 ? (
                      <>
                        {" "}
                        <CarouselControl
                          direction="prev"
                          directionText="Previous"
                          onClickHandler={this.previous}
                        />
                        <CarouselControl
                          direction="next"
                          directionText="Next"
                          onClickHandler={this.next}
                        />
                      </>
                    ) : null}
                  </Carousel>
                ) : (
                  // <CarouselItem>
                  <figure
                    style={{
                      backgroundImage: `url(${this.getDefaultImage(
                        this.state.offers.type
                      )})`,

                      backgroundSize: "cover",
                      // backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></figure>
                  // </CarouselItem>
                )}

                {this.state.offers.type !== null ? (
                  <div className="pills">
                    <span>{this.getType(this.state.offers.type)}</span>
                  </div>
                ) : null}
                <Col className="sectionOffer col-12 col-lg-7">
                  <div className="infoOffer">
                    <Row>
                      <div className="titleLegend">
                        {this.state.offers.subType}
                      </div>
                      <div className="title">{this.state.offers.label}</div>
                    </Row>
                    {/* <Row> */}

                    <div className="itemDetail">
                      <div className="Offer">
                        <span>{this.state.offers.offerTitle}</span>
                      </div>{" "}
                      <h4>
                        Détails{" "}
                        {this.state.offers.conditions ? "de l'offre" : null}
                      </h4>
                      <span>{this.state.offers.description}</span>
                      {this.state.offers.conditions ? (
                        <>
                          {" "}
                          <br />
                          <br />
                          <h4>Comment en bénéficier</h4>
                          <span>{this.state.offers.conditions}</span>
                          <br />
                          <br />
                        </>
                      ) : null}
                      {this.state.offers.address &&
                      this.state.hoster.hosterLat !== null &&
                      this.state.hoster.hosterLong !== null &&
                      this.state.activity.activityLat !== null &&
                      this.state.activity.activityLong !== null ? (
                        <>
                          <h4>Point de rencontre</h4>
                          <div className="localisation">
                            <a
                              href={`https://www.google.fr/maps/dir/'${this.state.hoster.hosterLat},${this.state.hoster.hosterLong}'/'${this.state.activity.activityLat},${this.state.activity.activityLong}'/`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconSpot />
                              <span>{this.state.offers.address.line1}, </span>
                              <span>
                                {this.state.offers.address.zipcode}{" "}
                                {this.state.offers.address.city}
                              </span>
                            </a>
                            <Map
                              activity={this.state.activity}
                              hoster={this.state.hoster}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col className="Infos">
                  <h4 className="title"> Infos & contact</h4>
                  <div className="description">
                    {this.state.offers.address ? (
                      <div className="localisation">
                        <IconSpot />
                        <span>{this.state.offers.address.line1}, </span>
                        <span>
                          {this.state.offers.address.zipcode}{" "}
                          {this.state.offers.address.city}
                        </span>
                      </div>
                    ) : null}

                    {this.state.offers.phone ? (
                      <div className="telephone">
                        <IconTel />
                        <span>{this.state.offers.phone}</span>
                      </div>
                    ) : null}
                    {this.state.offers.email ? (
                      <div className="email">
                        <IconEmail />
                        <span>{this.state.offers.email}</span>
                      </div>
                    ) : null}
                    <div className="open">
                      <IconOpen />
                      <span>Horaires d'ouverture</span>
                    </div>
                    <ul className="openingHours">
                      {this.getOpeningDays(this.state.offers.openingDays)}
                    </ul>
                  </div>
                  {this.state.offers.url !== null &&
                  this.state.offer_link.length > 5 ? (
                    <Row className="BtnContainer">
                      <Col>
                        <Button
                          className="customBtn btn-blue smooth-scroll btn-book"
                          href={this.state.offers.url}
                          target="_blank"
                        >
                          Réserver en ligne
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OfferDetails);
