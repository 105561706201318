/**
 * Return the post price string
 * @param {*} value 
 * @returns string
 */
export const getTextPostPrice = (value) => {
  switch (value) {
    case 'perDay' : return 'par jour.';
    case 'perNight' : return 'par nuit.';
    case 'perPerson' : return 'par personne.';
    case 'perSlot' : return 'par séance.';
    case 'perHour' : return 'par heure.';
    case 'perRoom' : return 'par chambre.';
    case 'perPet' : return 'par animal.';
    default: return '';
  }
}

/**
 * Return the pre price string
 * @param {*} value 
 * @returns string
 */
export const getTextPrePrice = (value) => {
  switch (value) {
    case 'from' : return 'à partir de ';
    default: return '';
  }
}