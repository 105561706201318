import React from "react";
import YouTube from "react-youtube";

function YoutubePlayer(props) {
  const [videoUrl, setVideoUrl] = React.useState(props.url);
  let video_id;
  if (videoUrl) {
    video_id = videoUrl.split("v=")[1].split("&")[0];
  }

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <>
      <YouTube
        videoId={video_id}
        containerClassName="embed embed-youtube"
        className="youtube_player"
        iframeClassName="youtube_iframe"
        opts={opts}
      />
    </>
  );
}

export default YoutubePlayer;
