import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import PdfDocument from "./PdfDocument";
import PdfControls from "./PdfControls";
import "./DisplayPdf.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { isMobile } from "react-device-detect";

const noop = () => {};

export default class DisplayPdf extends React.PureComponent {
  static propTypes = {
    ...Modal.propTypes,
    fitTo: PropTypes.oneOf(["page", "width"]),
  };
  static defaultProps = {
    fitTo: "page",
    open: false,
  };

  state = {
    scale: isMobile ? 0.60 : 1.6,
    loadedPages: {},
    open: false,
  };

  constructor(props) {
    super(props);
    this.state.fitTo = props.fitTo;
    this.state.open = props.open;
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
    if (nextProps.service !== this.state.serviceId) {
      this.setState({ serviceId: nextProps.service });
    }
  }

  handleDocumentLoaded = ({ numPages: pages }) => {
    this.setState({ page: 1, pages });
  };

  handlePageChange = (page) => this.setState({ page });

  render() {
    const { fitTo, scale, pages: pageCount, open } = this.state;
    return (
      <div className="">
        {/* <Modal
                    className={`document-preview-modal fit-to-${fitTo}`}
                    isOpen={this.state.open}
                    toggle={this.props.toggle}
                    fullscreen={true}
                >
                  <ModalHeader toggle={this.props.toggle} 
                  close={<button type="button" className="close" onClick={this.props.toggle}  ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>}
                  > */}
        <div className="headerPdf">
          <h5>
            {this.props.title}
            {/* {pageCount && (
                            <div className="page-counter">
                            Page {this.state.page} of {pageCount}
                            </div>
                        )} */}
          </h5>
        </div>
        {/* </ModalHeader>
                  <ModalBody> */}
        <div className={`viewerPdf-Body fit-to-${fitTo}`}>
          <PdfDocument
            file={this.props.file}
            scale={scale}
            fitTo={fitTo}
            onLoad={this.handleDocumentLoaded}
            onPageChange={this.handlePageChange}
          />
          {/* </ModalBody> */}
        </div>
        <PdfControls
          className=""
          fitTo={fitTo}
          onZoomToFit={(fitTo) => this.setState({ scale: isMobile ? 0.60 : 1.6, fitTo })}
          onZoomIn={() => this.setState({ scale: scale * 1.1 })}
          onZoomOut={() => this.setState({ scale: Math.max(0.1, scale * 0.9) })}
        />
        {/* {this.props.footer && (
                        <ModalFooter>{this.props.footer}</ModalFooter>
                    )} */}
        {/* </Modal> */}
      </div>
    );
  }
}
